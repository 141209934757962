import React from 'react'
import { navigate } from 'gatsby'
import algoliasearch from 'algoliasearch'
import {
  InstantSearch,
  SearchInput,
  Results,
  ResultColumn,
  ResultCount,
  AllResultsWrap,
} from './style.js'
import EventResult from './EventResult'
import OtherResult from './OtherResult'

import search from '../../images/search.svg'
import SearchBackground from './SearchBackground'

class SiteSearch extends React.Component {
  constructor(props) {
    super(props)

    this.algoliaSearchClient = this.initClient()
    this.queryInput = React.createRef()
    this.state = {
      searchOpen: false,
      query: '',
      events: {
        total: 0,
        results: [],
      },
      everythingElse: {
        total: 0,
        results: [],
      },
      nonce: {}, //new Object()
    }
  }

  searchPages(query) {
    const today = Date.parse(Date()) / 1000

    let nonce = this.nonce()
    let indexPrefix = process.env.GATSBY_ALGOLIA_INDEX_NAME_PREFIX

    let queries = [
      {
        indexName: `${indexPrefix}_posts`,
        query,

        params: {
          hitsPerPage: 3,
          filters: `event_enddate >= ${today}`,
          facetFilters: ['type:Events', 'type:-Industry Hub'],
        },
      },
      {
        indexName: `${indexPrefix}_posts`,
        query,
        params: {
          hitsPerPage: 3,
          filters: '',
          facetFilters: ['type:-Events', 'type:-Industry Hub'],
        },
      },
    ]

    this.algoliaSearchClient.search(queries, (err, response) => {
      if (err) {
        console.log(err)
        return
      }

      if (this.latestNonce(nonce)) {
        let state = this.parseResults(response)
        this.setState(state)
      }
    })
  }

  parseResults(res) {
    let events = res.results[0]
    let ee = res.results[1]
    let state = {
      events: {
        total: events.nbHits,
        results: this.parseHits(events.hits),
      },
      everythingElse: {
        total: ee.nbHits,
        results: this.parseHits(ee.hits),
      },
    }

    return state
  }

  parseHits(array) {
    return array.map(result => {
      return {
        title: result.post_title,
        url: result.permalink,
        content: result._snippetResult.content.value,
        key: result.post_id,
        types: result.type,
        image: result.img,
      }
    })
  }

  componentDidUpdate() {
    if (this.props.searchOpen) {
      setTimeout(() => {
        this.queryInput.current.focus()
      }, 100)
    } else {
      return null
    }
  }

  checkEnter(e) {
    if (e.key === 'Enter') {
      navigate(`/search?q=${this.state.query}`)
      this.props.closeSearchMenu(e)
    }
  }

  handleChange(e) {
    let query = e.target.value

    this.setState(
      {
        query,
      },
      () => this.searchPages(query.trim())
    )
  }

  initClient() {
    let key = process.env.GATSBY_ALGOLIA_SEARCH_API_KEY,
      id = process.env.GATSBY_ALGOLIA_APP_ID

    return algoliasearch(id, key)
  }

  nonce() {
    let nonce = {} //new Object()

    this.setState({
      nonce,
    })

    return nonce
  }

  latestNonce(nonce) {
    return nonce === this.state.nonce
  }

  render() {
    let totalResults = this.state.events.total + this.state.everythingElse.total

    return (
      <>
        <InstantSearch {...this.props} show={this.props.searchOpen}>
          <SearchInput
            className={this.state.query.length ? 'hasQuery' : 'noQuery'}
          >
            <div className="query">
              <img src={search} alt="search" />
              <input
                placeholder="Search&hellip;"
                ref={this.queryInput}
                value={this.state.query}
                onChange={this.handleChange.bind(this)}
                onKeyPress={this.checkEnter.bind(this)}
              />
            </div>
            <AllResults
              to={`/search?q=${this.state.query}`}
              count={totalResults}
            />
          </SearchInput>
          <SearchBackground
            show={this.props.searchOpen}
            value={this.state.query}
            everythingElse={totalResults}
          />
          <Results totalResults={totalResults}>
            <ResultColumn>
              <h3 className="title">
                <ResultCount>{this.state.events.total}</ResultCount>What's on
              </h3>
              {this.state.events.results.map(result => (
                <EventResult {...result} />
              ))}
            </ResultColumn>

            <ResultColumn>
              <h3 className="title">
                <ResultCount>{this.state.everythingElse.total}</ResultCount>
                Everything Else
              </h3>
              {this.state.everythingElse.results.map(result => (
                <OtherResult {...result} />
              ))}
            </ResultColumn>
          </Results>
        </InstantSearch>
      </>
    )
  }
}

export default SiteSearch

export const AllResults = ({ count, to }) => (
  <AllResultsWrap to={to}>
    <ResultCount>{count}</ResultCount>
    <span>view</span> All Results
  </AllResultsWrap>
)
