import React from 'react'
import moment from 'moment'
import { ResponsiveImage } from '../ResponsiveImage'
import {
  SizeContainer,
  Container,
  Wrapper,
  InnerWrapper,
  Pic,
} from './style.js'
import mobImage from '../../images/vb-logo-yellow.svg'

const PostDetails = props => {

  return (
    <SizeContainer {...props}>
      <Container>
        <Pic>
          {props.user.srcSet ? (
            <ResponsiveImage
              url={props.user.avatar_url}
              srcSet={props.user.srcSet}
              alt="Author"
            />
          ) : (
            <img src={mobImage} alt="" />
          )}
        </Pic>
        {/*
				# date(formatString: "DD MMM YYYY") */}

        <Wrapper>
          <InnerWrapper>
            {props.user.user_firstname ? (
              <h4>
                {props.user.user_firstname} {props.user.user_lastname}
              </h4>
            ) : (
              <h4>Visit Ballarat</h4>
            )}

            {props.date && <h6>{moment(props.date).format('DD MMM YYYY')}</h6>}
          </InnerWrapper>

          <p>
            Filed under
            <span
              dangerouslySetInnerHTML={{ __html: props.primaryCat.node.name }}
            />
          </p>
        </Wrapper>
      </Container>
    </SizeContainer>
  )
}

export default PostDetails
