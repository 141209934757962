import styled, { keyframes } from 'styled-components'

import Arrow from '../../images/search_arrow.svg'

export const ContainerSearchBackground = styled.div`
  display: none;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 1024px) {
    display: ${props =>
      !props.value && !props.everythingElse ? 'flex' : 'none'};
    margin: 0 auto;
    padding-top: 140px;
  }

  @media (min-width: 1280px) {
    padding-top: 180px;
  }
`

export const InnerContainerSearchBackground = styled.div`
  @media (min-width: 1024px) {
    width: 100%;
    text-align: center;
  }
`

const fadeUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);  }
`

export const ArrowImage = styled.svg`
  opacity: 0;
  display: ${props => (props.show ? 'block' : 'none')};
  animation: ${fadeUp} ease-out 400ms;
  animation-delay: 300ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; /*when the spec is finished*/

  @media (min-width: 768px) {
    background-image: url(${Arrow});
    background-repeat: no-repeat;
  }
  @media (min-width: 1024px) {
    width: 100px;
    position: absolute;
    right: calc(100% + 40px);
    background-size: 100px;
    height: 87px;
    transform: translateY(-60%);
    top: -50px;
  }

  @media (min-width: 1280px) {
    width: 162px;
    height: 141px;
    background-size: 162px;
    top: -105px;
  }

  @media (min-width: 1440px) {
    width: 190px;
    height: 165px;
    background-size: 190px;
    right: calc(100% + 60px);
    top: -130px;
  }
`

export const TitleSearchBackground = styled.h4`
  font-family: Freight Big;
  font-weight: 500;
  display: inline-block;
  font-size: 24px;
  color: #212c1f;
  letter-spacing: -0.5px;
  margin-bottom: 0;
  text-transform: lowercase;
  position: relative;

  ::first-letter {
    text-transform: uppercase;
  }

  @media (min-width: 768px) {
    font-size: 48px;
  }
  @media (min-width: 1024px) {
    font-size: 64px;
  }
`
export const SubTitleSearchBackground = styled.p`
  @media (min-width: 768px) {
    display: block;
    font-weight: 300;
    font-size: 16px;
    color: #545c52;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 0;
    padding-top: 10px;
    text-align: center;
  }

  @media (min-width: 1440px) {
    text-align: center;
  }
`
