import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useFavourites } from '../../utils/useFavourites'
import { ReactComponent as Heart } from '../../images/favorite.svg'
import { ReactComponent as Check } from '../../images/c-check.svg'
import { device } from '../../utils/breakpoints'
import { gql, useQuery } from '@apollo/client'

const FavouriteCart = () => {
  const { favourites, showToast, setFavourites, setShowToast } = useFavourites()

  const timer = () => {
    setTimeout(() => {
      setShowToast(false)
    }, 4000)
  }

  useEffect(() => {
    let timerId = null

    if (showToast) {
      clearTimeout(timerId)
      timerId = timer()
    }

    if (!showToast) {
      clearTimeout(timerId)
    }
  }, [showToast])

  if (!favourites) return null
  const count = favourites?.length
  const isEmpty = count === 0

  return (
    <Container>
      <LinkToFav
        isEmpty={isEmpty}
        onClick={() => {
          isEmpty
            ? setShowToast(
                <>
                  Click{' '}
                  <strong style={{ fontWeight: 500 }}>
                    "add to must-do's"
                  </strong>{' '}
                  or <Heart />
                  {
                    'where you see it to create your shareable Ballarat must-do list!'
                  }
                </>
              )
            : (window.location.href = '/favourites')
        }}
      >
        <Count isEmpty={isEmpty}>{count}</Count>
        <Heart />
      </LinkToFav>

      <Toast
        className={showToast ? 'show' : ''}
        onClick={() => {
          console.log('showToast:', showToast)
          console.log('helllo')
          showToast && setShowToast(false)
        }}
      >
        {/* <Check /> */}
        <span>{showToast}</span>
      </Toast>
    </Container>
  )
}

export default FavouriteCart

const Container = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 80px;
  right: 25px;

  @media (${device.desktop}) {
    bottom: 70px;
    right: 70px;
  }

  @media print {
    display: none;
  }
`

const LinkToFav = styled.div`
  height: 48px;
  width: 48px;
  background: #a55331;
  background: ${props => props.isEmpty && '#B9BAB4'};
  border: 1px solid white;
  box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.35);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  svg {
    height: 22px;
    width: 24px;
    g {
      fill: white;
      fill: ${props => props.isEmpty && '#E2DDD6'};
    }
  }

  transition: 120ms;
  opacity: 1;

  :hover {
    opacity: 0.8;
  }
`

export const Count = styled.div`
  background: #212c1f;
  border: 2px solid #fbf9f6;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  color: white;

  background: ${props => props.isEmpty && '#B9BAB4'};

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 6px;

  position: absolute;
  top: 0;
  right: 0;
  transform: translate3d(50%, -50%, 0);
`
export const Toast = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 14px;
  font-size: 16px;
  color: #545c52;
  background: white;
  width: max-content;
  max-width: calc(100vw - 120px);
  min-height: 48px;
  box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.35);

  svg {
    height: 17px;
    height: 17px;
    margin-right: 3px;
    flex-shrink: 0;
  }

  position: absolute;
  right: 60px;
  bottom: 0px;
  z-index: -1;

  opacity: 0;
  transform: translateX(50px);
  transition: 200ms ease-out;
  pointer-events: none;

  &.show {
    opacity: 1;
    transform: translateX(0px);
    pointer-events: auto;
    cursor: pointer;
  }

  span {
    margin-top: 1px;
  }
`

const GET_FAVOURITES = gql`
  query($favourites: [ID]!) {
    contentNodes(where: { in: $favourites }, first: 200) {
      nodes {
        ... on Operator {
          databaseId
        }
        ... on Event {
          databaseId
        }
      }
    }
  }
`
