import React, { Component } from 'react'
import { HamburgerWrapper, FirstLine, SecondLine, ThirdLine } from './style.js'

class Hamburger extends Component {
  render() {
    return (
      <HamburgerWrapper {...this.props}>
        <FirstLine {...this.props} />
        <SecondLine {...this.props} />
        <ThirdLine {...this.props} />
      </HamburgerWrapper>
    )
  }
}

export default Hamburger
