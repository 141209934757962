import styled from 'styled-components'
import { AvenueImg } from '../AvenueImg'
import FancyLink from '../../utils/FancyLink'

export const TeaserContainer = styled.div`
  overflow: ${props => props.festivalHighlight && 'hidden'};
  grid-column: ${props => (props.gridLarge ? 'span 2' : 'span 1')};
  grid-row: ${props =>
    props.gridLarge ? 'span 2' : props.gridLong ? 'span 2' : 'span 1'};
`

export const TeaserWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: ${props =>
    props.subcategory
      ? 'column'
      : props.header || props.standard || props.story
      ? 'row'
      : 'column'};
  padding-top: ${props =>
    props.removePadding ||
    props.subcategory ||
    props.relatedThreee ||
    props.more ||
    props.fullWidth ||
    props.storyMain
      ? '0'
      : props.standard
      ? '18px'
      : props.story
      ? '18px'
      : props.story
      ? '20px'
      : null};
  padding-bottom: ${props =>
    props.festivalStay
      ? '25px'
      : props.storyFullWidth
      ? '35px'
      : props.more
      ? '0px'
      : props.search
      ? '42px'
      : props.standard
      ? '21px'
      : props.story
      ? '20px'
      : props.fullWidth
      ? '52px'
      : props.storyMain
      ? '42px'
      : null};

  @media (min-width: 480px) {
    flex-direction: ${props =>
      props.standard ? 'column' : props.story ? 'column' : null};
  }

  @media (min-width: 768px) {
    align-items: ${props =>
      props.featured ? 'flex-start' : props.fullWidth ? 'center' : null};
    flex-direction: ${props =>
      props.festivalStay
        ? 'row'
        : props.header
        ? 'row'
        : props.search
        ? 'column'
        : props.standard
        ? 'column'
        : props.story
        ? 'row'
        : props.fullWidth
        ? 'row'
        : props.storyMain
        ? 'column'
        : 'column'};

    padding-top: ${props =>
      props.removePadding || props.relatedThree
        ? '0'
        : props.storyMain
        ? '0'
        : props.story
        ? '0'
        : props.standard
        ? '30px'
        : null};
    padding-bottom: ${props =>
      props.festivalStay
        ? '30px'
        : props.storyFullWidth
        ? ' 50px'
        : props.more
        ? '18px'
        : props.story
        ? '30px'
        : props.fullWidth
        ? '32px'
        : props.standard
        ? '40px'
        : null};
  }

  @media (min-width: 1024px) {
    padding-bottom: ${props => (props.storyMain ? '92px' : null)};
  }

  @media (min-width: 1280px) {
    padding-top: ${props =>
      props.removePadding || props.relatedThree
        ? '0'
        : props.standard
        ? '50px'
        : props.fullWidth
        ? '0px'
        : null};
    padding-bottom: ${props =>
      props.festivalStay
        ? '50px'
        : props.storyFullWidth
        ? ' 80px'
        : props.error
        ? '0'
        : props.subcategory
        ? '49px'
        : props.relatedTwo
        ? '62px'
        : props.relatedThree
        ? '76px'
        : props.more
        ? '18px'
        : props.search
        ? '52px'
        : props.standard
        ? '42px'
        : props.story
        ? '40px'
        : props.fullWidth
        ? '52px'
        : null};
  }

  /*BOTTOM LINE*/

  ::before {
    content: '';

    ${props =>
      props.one &&
      `
			content: none;
		`}

    position: absolute;
    bottom: 0;
    margin: 0;
    background: ${props => (props.white ? 'white' : 'black')};
    height: 1px;
    opacity: ${props => (props.white ? '0.4' : '1')};
    width: ${props =>
      props.white || props.story ? '100%' : !props.storyHp ? '600%' : '100%'};
    display: block;
    display: ${props =>
      props.festivalStay
        ? 'block'
        : props.relatedTwo
        ? 'none'
        : props.error
        ? 'none'
        : props.more
        ? 'none'
        : props.subcategory
        ? 'none'
        : props.noLine
        ? 'none'
        : props.lineD
        ? 'none'
        : props.standard
        ? 'block'
        : props.search
        ? 'block'
        : props.story
        ? 'block'
        : props.storyMain
        ? 'block'
        : props.fullWidth
        ? 'block'
        : 'none'};
    transform: ${props =>
      props.story || props.storyFullWidth || props.festivalStay
        ? 'none'
        : 'translateY(-2px)'};

    @media (min-width: 480px) {
      margin: 0;
      display: ${props =>
        props.noLine
          ? 'none'
          : props.standard
          ? 'none'
          : props.story
          ? 'none'
          : null};
    }

    @media (min-width: 768px) {
      margin: 0;
      display: ${props =>
        props.error
          ? 'none'
          : props.noLine
          ? 'none'
          : props.lineD
          ? 'block'
          : props.storyMain
          ? 'none'
          : props.standard
          ? 'block '
          : props.story
          ? 'block '
          : null};
    }
  }

  ::after {
    display: ${props =>
      props.relatedTwo
        ? ' none'
        : props.error
        ? 'none'
        : props.story ||
          props.fullWidth ||
          props.storyMain ||
          props.standard ||
          props.search
        ? 'block'
        : 'none'};
    content: '';
    position: absolute;
    height: 5px;
    width: ${props =>
      props.featured
        ? '65%'
        : props.story
        ? '27%'
        : props.fullWidth
        ? '50%'
        : '100%'};
    transform: ${props => (props.story ? 'translateY(2px)' : null)};
    left: 0;
    transition: 160ms;
    bottom: 0;
    opacity: 0;
    background-color: #a55331;
  }

  .gatsby-image-wrapper,
  .normal-image-wrapper {
    transition: transform 400ms ease-in-out;
  }

  :hover {
    .gatsby-image-wrapper,
    .normal-image-wrapper {
      transform: scale(1.05);
    }

    .hover-brown {
      color: #a55331;
    }

    .attraction-cta {
      color: #a55331;
    }
    /*brown span*/
    > div {
      h3 > a {
        color: ${props => (props.header ? '#a55331' : null)};
      }
      span:first-of-type {
        color: ${props =>
          props.search ||
          props.fullWidth ||
          props.standard ||
          props.storyFullWidth ||
          props.story
            ? '#a55331'
            : null};
        font-weight: ${props =>
          props.storyFullWidth || props.story ? '500' : null};
      }
    }
    /*brown line*/
    @media (min-width: 768px) {
      ::after {
        opacity: 1;
      }
    }

    /*overlay on hover*/

    .teaser-overlay {
      opacity: 0.2;
      transition: 400ms;
    }

    .icons-container {
      opacity: 1;
    }
  }
`

export const ImgColumn = styled.div`
  overflow: hidden;
  position: relative;
  width: ${props =>
    props.header
      ? '154px'
      : props.subcategory
      ? '100%'
      : props.fullWidth
      ? '100%'
      : props.standard
      ? '33.333%'
      : props.story
      ? '33.3333%'
      : null};
  margin-right: ${props =>
    props.standard ? '20px' : props.story ? '20px' : '0'};
  flex-shrink: 0;
  flex-grow: ${props => (props.gridLong || props.gridLarge ? '1' : null)};
  margin-bottom: ${props =>
    props.festivalStay
      ? '18px'
      : props.subcategory
      ? '22px'
      : props.standard
      ? '2px'
      : props.search
      ? '13px'
      : null};

  @media (min-width: 480px) {
    width: ${props => (props.standard ? '100%' : props.story ? '100%' : null)};
    margin-bottom: ${props =>
      props.standard
        ? '15px'
        : props.story
        ? '10px'
        : props.error
        ? '16px'
        : null};
  }

  @media (min-width: 768px) {
    width: ${props =>
      props.festivalStay
        ? '21%'
        : props.featured
        ? '66.5%'
        : props.fullWidth
        ? '50%'
        : props.standard
        ? '100%'
        : props.story
        ? '27%'
        : null};
    margin-right: ${props =>
      props.festivalStay
        ? '30px'
        : props.header
        ? '20px'
        : props.standard
        ? '20px'
        : props.story
        ? '20px'
        : '0'};
    margin-bottom: ${props =>
      props.festivalStay
        ? '0px'
        : props.story
        ? '0px'
        : props.search
        ? '24px'
        : null};
    height: ${props =>
      props.featured || props.storyFullWidth ? '576px' : null};
  }

  @media (min-width: 1024px) {
    margin-bottom: ${props =>
      props.standard ? '25px' : props.story ? '0px' : null};
  }

  @media (min-width: 1280px) {
    width: ${props => (props.featured ? '65%' : null)};
    margin-right: ${props =>
      props.festivalStay
        ? '50px'
        : props.header
        ? '30px'
        : props.standard
        ? '20px'
        : props.story
        ? '45px'
        : null};
    margin-bottom: ${props => props.error && '28px'};
  }
`

export const ImgMain = styled.img`
  margin: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: block;
`

export const ResponsiveImgMain = styled(AvenueImg)`
  margin: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
`

export const ImgWrap = styled.div`

	padding-top: ${props =>
    props.festivalStay
      ? '74.14%'
      : props.header
      ? '75%'
      : props.featured || props.storyFullWidth
      ? '100%'
      : props.standard
      ? '75%'
      : props.story
      ? '76.6%'
      : props.search
      ? '75%'
      : props.fullWidth
      ? '100%'
      : props.storyMain
      ? '62.9%'
      : props.itinerary
      ? '139%'
      : props.categoryLanding
      ? '75%'
      : '100%'};
	width: 100%;
	height: 100%;
	max-height: ${props =>
    props.festivalStay
      ? '259px'
      : props.header
      ? '115px'
      : props.search
      ? 'auto'
      : props.story
      ? '84px'
      : props.standard
      ? '84px'
      : props.storyMain
      ? '223px'
      : null};
	position: ${props => (props.gridLong ? 'absolute' : 'relative')};
	/* flex: ${props =>
    props.standard ? '1 0 33.33333%' : props.story ? '1 0 33.33333%' : null}; */


	cursor: pointer;

	@media (min-width: 768px) {
	position: ${props =>
    props.featured || props.storyFullWidth ? 'absolute' : 'relative'};
padding-top: ${props => (props.featured || props.storyFullWidth) && '0'};
		max-height: ${props =>
      props.standard ? 'auto' : props.search ? '193px' : 'auto'};
		flex-basis: ${props => (props.fullWidth ? '50%' : null)};
		grid-row: ${props => (props.storyMain ? 'span 2' : null)};
	}


	.gatsby-image-wrapper {
	position: ${props =>
    props.featured || props.storyFullWidth
      ? 'absolute'
      : 'absolute'} !important;

		top:0;
		width: 100%;
		overflow: hidden;
		height: 100%;

		@media (min-width: 768px) {
	position: ${props =>
    props.featured || props.storyFullWidth
      ? 'relative'
      : 'absolute'} !important;

		}

		/*icon gradient*/
		::before {
			content: "";
			background-image: linear-gradient(-250deg, rgba(0, 0, 0, 0.40) 10%, rgba(0, 0, 0, 0.00) 26%);
			z-index: 0;
			width: 100%;
			height: 100%;
			position: Absolute;
			display:${props => (props.standard || props.fullWidth ? 'block' : 'none')};
			top: 0;
			left: 0;
		}


		/*title gradient*/
		::after {
			content: "";
			background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0.2%, rgba(0, 0, 0, 0.40) 70%);
			z-index: 0;
			width: 100%;
			height: 80%;
			position: Absolute;
			display:${props =>
        props.itinerary || props.storyMain || props.categoryLanding
          ? 'block'
          : 'none'};
			bottom: 0;
			left: 0;
		}
	}

`

export const InnerInfo = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  /* z-index:1; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 2;
  color: #fbf9f6;
  width: 100%;
  padding: ${props =>
    props.gridLarge
      ? '0 20px 20px 20px'
      : props.storyMain
      ? '0 20px 18px 20px'
      : props.itinerary
      ? '0 20px 20px 20px'
      : props.categoryLanding
      ? '0 10px 10px 10px'
      : null};

  @media (min-width: 1024px) {
    padding: ${props =>
      props.storyMain
        ? ' 0 30px 28px 30px'
        : props.itinerary
        ? ' 0 30px 30px 30px'
        : props.categoryLanding
        ? ' 0 30px 30px 30px'
        : null};
  }

  div {
    display: flex;
    align-items: center;

    svg {
      stroke: #fbf9f6;
      opacity: 0.5;
      margin-right: 13px;
      width: 23px;
    }
    display: flex;
    position: relative;
    line-height: 100%;

    padding-bottom: ${props =>
      props.gridLarge
        ? '15px'
        : props.categoryLanding
        ? '10px'
        : props.storyMain
        ? '11px'
        : props.itinerary
        ? '12px'
        : null};
    font-size: ${props =>
      props.gridLarge
        ? '12px'
        : props.categoryLanding
        ? '10px'
        : props.storyMain
        ? '12px'
        : props.itinerary
        ? '12px'
        : null};

    @media (min-width: 768px) {
      font-size: 14px;
    }

    @media (min-width: 1024px) {
      padding-bottom: ${props =>
        props.gridLarge
          ? ' 26px'
          : props.categoryLanding
          ? ' 15px'
          : props.storyMain
          ? '18px'
          : null};
    }

    ::before {
      content: '';
      position: absolute;
      bottom: 0;
      margin: 0;
      background: white;
      height: 1px;
      width: 100%;
      display: ${props => (props.passAttraction ? 'none' : 'block')};
      opacity: 0.5;
    }
  }

  p {
    display: block;
    font-weight: 500;
    margin: 0;
    margin-right: 23px;
  }

  span {
    position: relative;

    ::before {
      position: absolute;
      content: '';
      left: -12px;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: white;
      opacity: 0.6;
    }
  }

  h3 {
    font-size: ${props =>
      props.gridLarge
        ? '24px'
        : props.categoryLanding
        ? '16px'
        : props.storyMain
        ? '24px'
        : props.itinerary
        ? '19px'
        : null};
    font-weight: 700;

    margin-top: ${props =>
      props.gridLarge
        ? '16px'
        : props.categoryLanding
        ? '8px'
        : props.storyMain
        ? '12px'
        : props.itinerary
        ? '12px'
        : '12px'};
    margin-bottom: 0;

    @media (min-width: 480px) {
      font-size: ${props =>
        props.gridLarge ? '26px' : props.categoryLanding ? '18px' : null};
    }

    @media (min-width: 768px) {
      font-size: ${props =>
        props.gridLarge
          ? '28px'
          : props.categoryLanding
          ? '18px'
          : props.storyMain
          ? '24px'
          : props.itinerary
          ? '19px'
          : null};
    }

    @media (min-width: 1024px) {
      margin-top: ${props => (props.storyMain ? '18px' : null)};
      font-size: ${props =>
        props.gridLarge
          ? '32px'
          : props.categoryLanding
          ? '23px'
          : props.storyMain
          ? '32px'
          : props.itinerary
          ? '23px'
          : null};
    }
  }

  a {
    text-decoration: none;
    color: #fbf9f6;
    :before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${props => (props.one ? 'flex-start' : 'space-between')};
  .brownBtn {
    margin-top: 0;
    justify-self: flex-end;
  }
`

export const TeaserInfo = styled.div`
	display: flex;
	flex-direction:column;
  height:100%;
	margin-top: ${props => (props.fullWidth ? '30px' : null)};
	grid-column: ${props =>
    props.standard ? 'span 2' : props.story ? 'span 2' : null};
	padding: ${props =>
    props.fullWidth
      ? '0 20px 0 10px'
      : props.storyMain
      ? '0 30px 0 10px'
      : null};
	padding-right: ${props => (props.standard ? '14px' : null)};

	@media (min-width: 768px) {
		padding-left: ${props =>
      props.featured ? '30px' : props.fullWidth ? '50px' : '0'};
		padding-right: ${props =>
      props.festivalStay
        ? '20px'
        : props.featured
        ? '10px'
        : props.standard
        ? '10px'
        : props.fullWidth
        ? '40px'
        : props.storyMain
        ? '20px'
        : '0'};
		grid-column: ${props => (props.story ? 'span 2' : null)};
		margin-top: ${props => (props.fullWidth ? '0px' : null)};
		justify-content: ${props =>
      props.featured ? 'none' : props.fullWidth ? 'center' : null};
		flex-basis: ${props => (props.fullWidth ? '50%' : null)};
	}

	@media (min-width: 1280px) {
		padding-left: ${props =>
      props.featured
        ? '55px'
        : props.standard
        ? '0'
        : props.fullWidth
        ? '88px'
        : '0'};
		padding-right: ${props =>
      props.festivalStay
        ? '80px'
        : props.featured
        ? '0'
        : props.standard
        ? '0px'
        : props.fullWidth
        ? '60px'
        : props.storyMain
        ? '20px'
        : '0'};
	}

	@media (min-width: 1440px) {
		padding-left: ${props => (props.whatson ? '55px' : null)};

	}

	div:first-of-type{
		display:${props => (props.storyFullWidth ? 'none' : null)};
	}
	div {
		display:${props =>
      props.passAttraction
        ? 'flex'
        : props.festivalStay
        ? 'grid'
        : props.storyMain
        ? 'none'
        : 'flex'};
		justify-self: flex-end;
		align-items:center;
		grid-template-columns: auto;
		margin-top: ${props => (props.passAttraction ? '20px' : null)};

		@media (min-width: 768px) {
			display:${props =>
        props.passAttraction ? 'flex' : props.storyMain ? 'none' : 'flex'};
			margin-top: ${props => (props.passAttraction ? '28px' : null)};

			}

 

		span {
			display:inline-block;
			margin-bottom: ${props =>
        props.festivalStay
          ? '0'
          : props.search
          ? '8px'
          : props.story
          ? '10px'
          : props.fullWidth
          ? '10px'
          : props.standard
          ? '10px'
          : '10px'};

			@media (min-width: 768px) {
				margin-bottom: ${props =>
          props.festivalStay
            ? '0'
            : props.search
            ? ' 13px '
            : props.story
            ? '8px'
            : props.fullWidth
            ? '15px'
            : props.standard
            ? '8px'
            : '12px'};
			}

			@media (min-width: 1024px) {
				margin-bottom: ${props =>
          props.festivalStay
            ? '0'
            : props.search
            ? '13px'
            : props.story
            ? '14px'
            : props.fullWidth
            ? '16px'
            : props.standard
            ? '14px'
            : '10px'};
			}
		}

		span:first-of-type {
			font-weight: ${props =>
        props.passAttraction ? null : props.search ? null : '500'};
			margin-right:  ${props => (props.search ? null : '24px')};
			margin-bottom:${props => (props.festivalStay ? '10px' : 'none')};
			flex-shrink:${props => (props.passAttraction ? '0' : '1')};
			word-break:break-word;
			line-height: 120%;

			@media (min-width: 768px) {
				margin-bottom:${props => (props.festivalStay ? '0' : null)};

			}
		}

		span:nth-of-type(2) {
			margin-right: auto;
			z-index: -1;
			position:relative;
			font-weight: ${props => (props.festivalStay ? '400' : '300')};
			color: ${props => (props.festivalStay ? '#212C1F' : null)};
      max-height:18px;
			flex-shrink:0;
			::before {
				display: ${props => (props.search || props.festivalStay ? 'none' : 'block')};
				position: absolute;
				content: "";
				left: -12px;
				top: 0;
				height: 100%;
				width: 1px;
				background-color: #545C52;
				opacity: 0.6;

			@media (min-width: 768px) {
				display: ${props => (props.search ? 'none' : 'block')};

			}
			}

			::after {
				display: ${props => (props.festivalStay ? 'block' : 'none')};
				position: absolute;
				content: "";
				bottom: -2px;
				left:0;
				height: 1px;
				width: 100%;
				background-color: #a55331;;
				opacity: 1;
			}
		}
	}

	span {
		font-size:${props =>
      props.passAttraction ? '14px' : props.search ? '11px' : '12px'};
		font-weight:${props => (props.storyFullWidth ? '500' : null)};
		color:${props => (props.storyFullWidth ? 'black' : null)};
		display: ${props => (props.storyMain ? 'none' : 'inline')};
		line-height:100%;

		@media (min-width: 768px) {
			font-size:${props =>
        props.passAttraction ? '16px' : props.search ? '12px' : null};
		}

		@media (min-width: 1024px) {
			font-size:${props =>
        props.passAttraction
          ? '18px'
          : props.festivalStay
          ? '16px'
          : props.search
          ? '14px'
          : '14px'};
		}

		@media (min-width: 1280px) {
			font-size:${props =>
        props.fullWidth
          ? '12px !important'
          : props.passAttraction
          ? '20px'
          : null};
		}

		@media (min-width: 1440px) {
			font-size:${props => (props.fullWidth ? '14px !important' : null)};
		}
	}

	a {
		text-decoration: none;
		color: #212C1F;
		cursor: pointer;

		:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 12;
		}
	}

	h3 {
		position: ${props => (props.festivalHighlight ? 'relative' : null)};
		font-size: ${props =>
      props.festivalStay
        ? '20px'
        : props.featured
        ? '24px'
        : props.header
        ? '18px'
        : props.search
        ? '18px'
        : props.standard
        ? '18px'
        : props.fullWidth
        ? '32px'
        : props.story
        ? '18px'
        : '30px'};
		margin: 0;
			
		margin-bottom: ${props =>
      props.festivalStay
        ? '12px'
        : props.subcategory
        ? '12px'
        : props.search
        ? '10px'
        : props.standard
        ? '10px'
        : props.story
        ? '0px'
        : props.fullWidth
        ? '18px'
        : '20px'};
			padding-right: ${props =>
        props.standard ? '0x' : props.story ? '15px' : null};
		display: ${props => (props.storyMain ? 'none' : 'null')};
		grid-row: ${props => (props.storyMain ? '1 / span 1' : 'null')};
		line-height: 100%;
		word-break: break-word;

		/* margin-top: ${props => (props.subcategory ? '20px' : 'null')}; */
		border-bottom: ${props =>
      props.storyFullWidth ? '1px solid rgba(0,0,0, 0.7)' : 'none'};
		padding-bottom:${props =>
      props.festivalHighlight ? '12px' : props.storyFullWidth ? '20px' : null};

		a {
			text-decoration: none;
			:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 12;
			}
		}

		@media (min-width: 768px) {
			/* margin-top: ${props => (props.subcategory ? '24px' : 'null')}; */
			margin-bottom: ${props =>
        props.festivalStay
          ? '10px'
          : props.header
          ? '12px'
          : props.story
          ? '0px'
          : props.standard
          ? '20px'
          : props.fullWidth
          ? '14px'
          : props.search
          ? '13px'
          : '20px'};
			font-size: ${props =>
        props.festivalStay
          ? '22px'
          : props.featured
          ? '28px'
          : props.standard
          ? '20px'
          : props.fullWidth
          ? '34px'
          : props.storyMain
          ? '32px'
          : props.search
          ? '22px'
          : null};

			padding-right: ${props =>
        props.standard ? '0px' : props.story ? '0px' : null};
		padding-bottom:${props => (props.festivalHighlight ? '17px' : null)};

		}

		@media (min-width: 1280px) {
				
			margin-bottom: ${props =>
        props.festivalStay
          ? '20px'
          : props.standard
          ? '20px'
          : props.fullWidth
          ? '25px'
          : null};
			font-size: ${props =>
        props.festivalStay
          ? '22px'
          : props.featured
          ? '32px'
          : props.standard
          ? '28px'
          : props.fullWidth
          ? '32px'
          : props.story
          ? '22px'
          : null};
		}

			@media (min-width: 1440px) {
				font-size: ${props =>
          props.featured ? '38px' : props.fullWidth ? '42px' : null};
			}

	
	}

	p {
		word-break: break-word;
	
		&.attraction-cta {
			width:auto;
			margin-right: auto;
			display: inline;
			font-weight: 500;
			color: #212C1F;
			margin-top:10px;
			border-bottom: 1px solid #a55331;
			@media(min-width: 1024px) {
				margin-top: 20px;
			}
		}

		em {
			font-style: normal;
			background-color: ${props => (props.search ? '#F2E4CD' : null)};
		}

		a {
			color: #545C52;
			:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 12;
			}
		}
		max-width: 750px;
		font-weight: 300;
		line-height: 150%;
		margin-top: ${props => (props.storyMain ? '20px' : null)};
		margin-bottom: 0;
		margin-bottom:${props =>
      props.bookingLink
        ? '20px'
        : props.festivalStay
        ? '14px'
        : props.storyFullWidth
        ? '20px'
        : null};
		padding-right: ${props =>
      props.standard ? '0px' : props.fullWidth ? '0px' : '0'};
		overflow: ${props =>
      props.standard
        ? 'hidden'
        : props.search
        ? 'hidden'
        : props.fullWidth
        ? 'hidden'
        : 'hidden'};
		font-size: ${props =>
      props.festivalStay
        ? '16px'
        : props.header
        ? '16px'
        : props.search
        ? '14px'
        : props.standard
        ? '14px'
        : props.fullWidth
        ? '16px'
        : props.storyMain
        ? '16px'
        : props.fullWidth
        ? '14px'
        : '10px'};
		max-height: ${props => (props.subcategory ? '63px' : null)};

		display: ${props =>
      props.error
        ? 'block'
        : props.subcategory
        ? 'block'
        : props.standard
        ? 'none'
        : props.story
        ? 'none'
        : null};

		@media (min-width: 480px) {
			display: ${props => (props.standard ? 'block' : null)};
			font-size: ${props => (props.relatedTwo ? '18px' : null)};
		}

		@media (min-width: 768px) {
			display: ${props => (props.standard ? 'inline' : null)};

			margin: ${props => (props.storyMain ? '0px' : null)};
			margin-top: ${props => (props.storyMain ? '30px' : null)};
			margin-bottom:${props => (props.storyFullWidth ? '35px' : null)};

			font-size: ${props =>
        props.festivalStay
          ? '17px'
          : props.featured
          ? '17px'
          : props.storyMain
          ? '18px'
          : props.fullWidth
          ? '20px'
          : props.standard
          ? '16px'
          : props.search
          ? '16px'
          : null};
			max-height: ${props =>
        props.header ? '72px' : props.standard ? '96px' : null};

		}

		@media (min-width: 1024px) {
			max-height: ${props => (props.standard ? '100%' : null)};
			font-size: ${props =>
        props.featured ? '20px' : props.standard ? '18px' : null};
			margin-bottom:${props =>
        props.bookingLink ? '40px' : props.festivalStay ? '35px' : null};

		}

		@media (min-width: 1280px) {
			margin-top: ${props => (props.storyMain ? '40px' : null)};
			
			font-size: ${props =>
        props.festivalStay ? '20px' : props.storyMain ? '20px' : null};

		}


		@media (min-width: 1440px) {
			
			font-size: ${props => (props.fullWidth ? '20px !important' : null)};

		}
	}

  >.region {
    margin-top:auto;
    padding-top:16px;
    
		@media (min-width: 1024px) {
      padding-top: ${props => (props.story ? '16px' : '32px')};
      
      margin-bottom: ${props => (props.story ? '-5px' : '-10px')};
    }

    &.festivalHighlight {
      position:relative;
      align-self:flex-start;
      padding-top:0;
      margin-top:-10px;
      margin-bottom:10px;
      
      @media (min-width: 1024px) {
        margin-top:-12px;
      }

      ::after {
        position:absolute;
        content:'';
        bottom: 7px;
        right: -10px;
        transform: translateX(100%);
        width: 400px;
        height: 1px;
        background: #B9BAB4;

        @media (min-width: 1024px) {
          bottom: 10px;
        }
      }
    }
  }
`

export const Featured = styled.h5`
  font-size: 23px;

  font-family: Freight Big;
  text-transform: none;
  position: relative;
  padding-left: 8px;
  overflow: visible;
  padding-bottom: 15px;
  padding-top: 15px;

  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
    background: black;
    height: 1px;
    width: 130%;
    display: block;

    @media (min-width: 768px) {
      right: auto;
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    padding-left: 30px;
    padding-top: 0px;
    margin-bottom: 30px;
    font-size: 26px;
  }

  @media (min-width: 1280px) {
    padding-bottom: 20px;
    margin-bottom: 50px;
    padding-left: 55px;
    font-size: 24px;
  }
`

export const IconsContainer = styled.div`
  pointer-events: none;
  display: ${props =>
    props.storyMain
      ? 'block'
      : props.standard
      ? 'block'
      : props.fullWidth
      ? 'block'
      : props.search
      ? 'block'
      : 'none'};

  position: absolute;
  bottom: -2px;
  left: 0;
  top: ${props => (props.storyMain ? '0' : null)};
  opacity: 0.5;
  transition: 160ms;
  z-index: 15;
  width: 100%;
  margin-left: -2px;
`

export const CircleIcon = styled.div`
  z-index: 4;
  display: flex;
  position: absolute;
  top: ${props => (props.standard ? '5px' : props.fullWidth ? '15px' : '15px')};
  left: ${props =>
    props.standard ? '5px' : props.fullWidth ? '15px' : '15px'};
  width: ${props =>
    props.standard ? '36px' : props.fullWidth ? '49px' : '30px'};
  height: ${props =>
    props.standard ? '36px' : props.fullWidth ? '49px' : '30px'};
  border: 2px solid white;
  background-color: ${props => (props.soldOut ? '#A7444D' : '#8ac191')};
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  @media (min-width: 480px) {
    top: ${props => (props.standard ? '15px' : null)};
    left: ${props => (props.standard ? '15px' : null)};
    width: ${props => (props.standard ? '49px' : null)};
    height: ${props => (props.standard ? '49px' : null)};
  }

  h3 {
    font-size: ${props =>
      props.standard ? '10px' : props.fullWidth ? '12px' : '12PX'};
    font-weight: 600;
    margin: 0;
    color: white;
    text-align: center;
    line-height: 100%;
    padding-top: ${props => (props.soldOut ? '4px' : '0')};

    @media (min-width: 480px) {
      font-size: ${props => (props.standard ? '12px' : null)};
    }
  }

  @media (min-width: 768px) {
    display: ${props =>
      props.standard ? 'grid' : props.fullWidth ? 'grid' : 'none'};
  }
`

export const HorizontalLine = styled.div`
  background: ${props => (props.white ? 'white' : 'black')};
  opacity: ${props => (props.white ? '0.4' : '1')};
  height: 1px;
  width: ${props => (props.white ? '100%' : '600%')};
  margin: 0;
  display: ${props => (props.storyMain ? 'block' : 'block')};

  @media (min-width: 768px) {
    margin: 0;
    display: ${props => (props.storyMain ? 'none' : 'block')};
  }

  @media (min-width: 1280px) {
    margin: 0;
  }
`

export const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 150ms;
  background: black;
  z-index: 2;
  opacity: 0;
`

export const DateRow = styled.div`
  display: flex;
  align-items: flex-start !important;
  justify-content: space-between;
`

export const RegionDateRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start !important;

  @media (min-width: 480px) {
    .region {
      margin-top: -1px;
      /* border-right: ${props =>
        props.showLine ? '1px solid #e8e0d3' : 'none'}; */
      padding-right: 14px;
      /* margin-right: 2px; */
      /* margin-bottom: 14px; */
    }
  }

  @media (min-width: 768px) {
    .region {
      border-right: none;
      margin-bottom: 0px;
    }
    /* margin-bottom: 6px; */
  }

  @media (min-width: 1430px) {
    flex-direction: row;
    .region {
      border-right: ${props => (props.showLine ? '1px solid #e8e0d3' : 'none')};
      margin-bottom: 14px;
    }
    margin-bottom: 0px;
  }
`

export const TitleRow = styled(DateRow)`
  svg {
    margin-top: 2px;
  }
`
export const FavouriteWrapper = styled.div`
  margin-left: 20px;
`

export const RegionTagWrapper = styled(FancyLink)`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 15;
  font-size: 12px;
  font-weight: 500;
  opacity: 1;
  transition: 100ms;
  @media (min-width: 1024px) {
    font-size: 14px;
  }

  svg {
    margin-right: 6px;
    margin-top: -2px;
    > g {
      > g {
        fill: ${props =>
          props.text === 'Ballarat'
            ? '#A55331'
            : props.text === 'Golden Plains'
            ? '#A78856'
            : props.text === 'Moorabool'
            ? '#405851'
            : props.text === 'Pyrenees'
            ? '#226594'
            : props.text === 'Hepburn'
            ? '#5D8863'
            : null};
      }
    }
  }

  &:hover {
    opacity: 0.7;
  }
`
