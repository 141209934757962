import React from 'react'
import { RegionTagWrapper } from './style'
import { ReactComponent as RegionIcon } from '../../images/region.svg'
const RegionTag = ({ title, label, link, regionField, className }) => {
  const text = title || label
  const uri = regionField?.regionPage?.uri
  const href = uri || link
  const cleanedHref = href?.replace(
    'cms.visitballarat.com.au',
    'visitballarat.com.au'
  )

  if (
    !text ||
    (text !== 'Ballarat' &&
      text !== 'Pyrenees' &&
      text !== 'Moorabool' &&
      text !== 'Golden Plains' &&
      text !== 'Hepburn')
  )
    return null

  return (
    <RegionTagWrapper
      href={cleanedHref}
      text={text}
      className={`${className} region`}
    >
      <RegionIcon />
      {text}
    </RegionTagWrapper>
  )
}

export default RegionTag
