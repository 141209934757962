import styled, { keyframes } from 'styled-components'
import { Link } from 'gatsby'

const lineScale = keyframes`
  0% {
		transform: scale(0);
  }
  100% {
		transform: scale(1);
  }
`

const fadeIn = keyframes`
  0% {
		opacity:0;
  }
  100% {
		opacity:1;
  }
`

export const ToggleSearch = styled.button`
  position: relative;
  background: ${props =>
    props.searchOpen ? '#FBF9F6' : 'transparent'} !important;
  z-index: 5;

  @media (min-width: 1024px) {
    background: transparent !important;

    :before,
    :after {
      content: '';
      bottom: 0;

      height: 100%;
      position: absolute;
      width: 1px;
      left: 0;
      background: ${props =>
        props.sticky
          ? 'transparent'
          : props.home
          ? 'rgba(255, 255, 255, 0.3)'
          : 'transparent'};
    }

    :after {
      left: auto;
      right: 0;
    }

    &.home {
      :before,
      :after {
        animation: ${lineScale} 420ms;
        animation-delay: 900ms;
        transform-origin: bottom;
        animation-fill-mode: both;
      }

      &.home {
        svg {
        }
        :after {
          animation-delay: 1000ms;
        }
      }
    }

    svg {
      display: block;
      path {
        fill: ${props =>
          props.sticky
            ? '#212C1F'
            : props.searchOpen
            ? '#A55331'
            : '#FFFFFF'} !important;
        stroke: ${props => (props.searchOpen ? '#A55331' : 'none')} !important;

        @media (min-width: 1024px) {
          background: transparent !important;
          fill: ${props =>
            props.sticky
              ? '#212C1F'
              : props.sticky && props.searchOpen
              ? '#212C1F'
              : props.searchOpen
              ? '#FFFFFF'
              : '#FFF'} !important;

          stroke: ${props =>
            props.sticky && props.searchOpen
              ? '#fff'
              : props.searchOpen
              ? '#FFF'
              : 'none'} !important;
        }
      }
    }
    div {
      @media (min-width: 1024px) {
        &.home {
          animation: ${fadeIn} 430ms;
          animation-delay: 1080ms;
          animation-fill-mode: both;
        }
      }
    }
  }

  @media print {
    display: none !important;
  }
`

export const NavContainer = styled.div`
  position: ${props => (props.sticky ? 'fixed' : 'absolute')};
  z-index: ${props => (props.sticky ? '1000' : newFunction())};
  width: 100%;
  top: ${props => (props.sticky ? '-1px' : '0')};
  left: 0;
  pointer-events: all;
`

export const NavWrapper = styled.div`
  background: ${props =>
    props.sticky
      ? '#fff'
      : props.headerImage
      ? 'transparent'
      : props.generic
      ? '#212C1F'
      : 'transparent'};
  margin: 0 auto;
  position: relative;

  width: 100%;
  z-index: ${props => (props.sticky ? '1000' : '11')};
  transition: ${props =>
    props.normal
      ? 'transform 240ms ease-out'
      : props.hideNav
      ? 'transform 240ms ease-out'
      : props.showNav
      ? 'transform 240ms ease-out'
      : 'none'};
  transform: ${props =>
    props.normal
      ? 'translateY(0)'
      : props.hideNav
      ? 'translateY(-135%)'
      : props.showNav
      ? 'translateY(0)'
      : props.sticky
      ? 'translateY(-135%)'
      : null};
  box-shadow: ${props =>
    props.sticky
      ? '0 10px 35px 0 rgba(0,0,0,0.10)'
      : props.home || props.headerImage
      ? 'none'
      : 'none'};
  height: 60px;
  max-width: ${props => (props.sticky ? '1440px' : '1380px')};
  display: grid;
  position: relative;
  place-items: center;
  grid-template-columns: ${props =>
    props.generic || props.sticky ? '1fr 120px 60px' : '60px 60px'};
  grid-template-rows: 60px;
  justify-content: ${props => (props.home ? 'end' : null)};

  border-bottom: ${props =>
    props.home ? 'none' : '1px solid rgba(255,255,255,0.3)'};

  @media (min-width: 768px) {
    height: 60px;
    gap: ${props => (props.home ? 0 : '20px')};
    grid-template-columns: ${props =>
      props.sticky
        ? '1fr 60px 60px'
        : props.generic
        ? '1fr 120px 60px'
        : '60px 60px'};
    grid-template-rows: 60px;
  }

  @media (min-width: 1024px) {
    border-bottom: ${props =>
      props.home ? 'none' : '1px solid rgba(255,255,255,0.3)'};
    height: 70px;
    gap: 0;
    grid-template-columns: ${props =>
      props.sticky
        ? '70px 1fr auto 70px'
        : props.mobHeader
        ? '180px 1fr auto'
        : props.generic
        ? '80px 1fr auto 70px'
        : '1fr auto 70px'};
    grid-template-rows: 70px;

    :before {
      content: '';
      top: 0;
      height: 100%;
      position: absolute;
      width: 1px;
      left: 0;
      background: ${props =>
        props.sticky
          ? 'transparent'
          : props.home
          ? 'rgba(255, 255, 255, 0.3)'
          : 'transparent'};
    }

    &.home {
      :before {
        transform-origin: top;
        animation: ${lineScale} 230ms;
        animation-fill-mode: both;
      }
    }

    :after {
      content: '';
      bottom: 0;
      height: 1px;
      position: absolute;
      width: 100%;
      background: ${props =>
        props.sticky
          ? 'transparent'
          : props.home
          ? 'rgba(255, 255, 255, 0.3)'
          : 'transparent'};
      left: 0;
    }

    &.home {
      :after {
        transform-origin: left;
        animation: ${lineScale} 760ms ease-in-out;
        animation-delay: 270ms;
        animation-fill-mode: both;
      }
    }
  }

  @media (min-width: 1280px) {
    height: ${props => (props.sticky ? '80px' : '101px')};
    border: ${props =>
      props.sticky
        ? 'none'
        : props.home
        ? 'none'
        : '1px solid rgba(255,255,255,0.3)'};
    grid-template-columns: ${props =>
      props.sticky
        ? '90px 1fr auto 80px'
        : props.mobHeader
        ? '180px 1fr auto'
        : props.generic
        ? '180px 1fr auto 100px'
        : '1fr auto 100px'};
    grid-template-rows: ${props => (props.sticky ? '80px' : '100px')};
  }

  @media (min-width: 1380px) {
    height: ${props =>
      props.sticky ? '80px' : props.home ? '101px' : '101px'};
  }

  button {
    width: 100%;
    height: 100%;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    margin: 0;
    border-bottom: ${props =>
      props.sticky ? 'none' : '1px solid rgba(255,255,255,0.3)'};

    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid rgba(255, 255, 255, 0.3);

    @media (min-width: 1024px) {
      border-left: 1px solid rgba(255, 255, 255, 0);
      border: none;
    }
  }
`

export const Nav = styled.nav`
  z-index: 20;
  position: absolute;
  top: 59px;
  width: 100%;
  background: #fbf9f6;
  transition: 360ms;
  pointer-events: ${props => (props.isOpen ? 'all' : 'none')};
  opacity: ${props => (props.isOpen ? '1' : '0')};
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 0 30px;
  padding-bottom: 28px;
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.15);
  justify-self: ${props => props.home && 'left'};

  @media (min-width: 768px) {
    transition: none;

    border: none;
    transform: none;
  }
  @media (min-width: 1024px) {
    pointer-events: all;
    opacity: 1;
    position: static;
    padding: 0;
    background: none;
    top: auto;
    padding: 0;
    box-shadow: none;

    display: flex;
    justify-content: ${props =>
      props.home ? null : props.generic ? 'center' : null};
    padding-left: ${props => (props.home ? '1.5%' : null)};
    padding-right: ${props => (props.home ? '1.5%' : null)};
  }

  @media (min-width: 1280px) {
    padding-left: ${props => (props.sticky ? '4%' : props.home ? '3%' : null)};
    padding-right: ${props => (props.sticky ? '4%' : props.home ? '3%' : null)};
  }

  ul {
    list-style: none !important;
    margin: 0;

    @media (min-width: 1024px) {
      display: flex;
      justify-content: ${props =>
        props.sticky ? 'center' : props.generic ? 'center' : null};
      justify-content: space-between;
    }

    @media (min-width: 1280px) {
      max-width: ${props => (props.sticky ? '1073px' : null)};
    }
  }
`

export const ListItem = styled.li`
  list-style-type: none !important;
  margin: 0;
  text-align: left;
  padding-left: 0;
  position: relative;
  display: flex;
  flex-direction: ${props => props.back && 'row-reverse'};
  justify-content: space-between;
  align-items: center;

  &.home {
    :nth-of-type(1) {
      animation-delay: 150ms;
    }

    :nth-of-type(2) {
      animation-delay: 250ms;
    }
    :nth-of-type(3) {
      animation-delay: 350ms;
    }
    :nth-of-type(4) {
      animation-delay: 450ms;
    }
    :nth-of-type(5) {
      animation-delay: 550ms;
    }
    :nth-of-type(6) {
      animation-delay: 650ms;
    }

    :nth-of-type(7) {
      animation-delay: 750ms;
    }
  }

  > img {
    margin: 0;
    height: 12px;
    margin-right: ${props => props.back && '15px'};
  }

  &.back--visit {
    > img {
      transform: rotate(180deg);
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }

  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    margin: 0;
    background: #b9bab4;
    height: 1px;
    opacity: 1;
    width: 100%;
    display: block;
  }

  @media (min-width: 1024px) {
    &.home {
      animation: ${fadeIn} 350ms ease-in-out;
      animation-fill-mode: both;
    }

    display: inline-block;

    font-weight: 400;
    margin: 0;
    position: static;

    > img {
      display: none;
    }

    ::before {
      display: none;
    }

    :hover {
      a {
        span::after {
          transform: translateY(-3px);
          opacity: 1;
        }
      }
    }
  }

  ::after {
    content: '';
    display: ${props =>
      props.sticky
        ? ' none'
        : props.hovered && props.megaMenu
        ? 'block'
        : '	none'};
    position: absolute;
    height: 60px;
    left: ${props => (props.sticky ? '120px' : '36px')};
    top: ${props => (props.sticky ? '50px' : '55px')};
    z-index: 100;
    /* border: 1px solid red; */
    width: ${props => (props.sticky ? '70%' : '82%')};

    @media (min-width: 1280px) {
      top: ${props => (props.sticky ? '55px' : '70px')};
      height: 100px;
    }

    @media (min-width: 1460px) {
      left: ${props => (props.sticky ? '150px' : '0')};
      width: ${props => (props.sticky ? '70%' : '85%')};
    }
  }

  > a {
    width: 100%;
    text-decoration: none;
    color: #212c1f;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.1px;
    line-height: 64px;

    :before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    @media (min-width: 1024px) {
      padding: ${props =>
        props.sticky
          ? '10px 8px 10px 8px'
          : props.generic
          ? '10px 12px 10px 11px'
          : '	10px 10px 10px 10px'};
      line-height: 100%;
      position: relative;
      font-size: 13px;
      color: ${props => (props.sticky ? '#212c1f' : '#fff')};
    }
    @media (min-width: 1440px) {
      font-size: 14px;
      position: relative;
      top: 1px;
      padding: ${props =>
        props.sticky
          ? '10px 20px 10px 21px'
          : props.generic
          ? '10px 21px 10px 21px'
          : props.home
          ? '	10px 20px 10px 20px'
          : props.mobHeader
          ? '10px 20px 10px 28px'
          : '	10px 16px 10px 16px'};
    }

    span {
      text-transform: uppercase;
      position: relative;
      ::after {
        content: '';
        position: absolute;
        bottom: -6px;
        height: 2px;
        width: 100%;
        left: 0;
        opacity: 0;
        transition: 230ms;

        background-color: ${props => (props.sticky ? '#212C1F' : '#ffffff')};
      }
    }
  }
`

export const InstagramDropDown = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  border-right: ${props =>
    props.sticky
      ? '1px solid rgba(233,224,211, 1)'
      : props.home
      ? '1px solid transparent'
      : '1px solid rgba(255, 255, 255, 0.3);'};

  button {
    width: auto;
    padding-right: 25px;

    svg {
      margin: 0;

      g {
        g {
          fill: ${props => (props.sticky ? '#212C1F' : '#FFFFFF')};
        }
      }

      :first-of-type {
        transition: 400ms;
        transform: ${props =>
          props.dropDownOpen ? 'rotateX(180deg)' : 'rotateX(0deg)'};
      }

      :last-of-type {
        margin-left: 12px;
        @media (min-width: 1280px) {
          margin-left: 18px;
        }
      }
    }

    @media (min-width: 1280px) {
      padding-right: 35px;
    }
  }

  display: none;

  @media (min-width: 1024px) {
    &.home {
      animation: ${fadeIn} 430ms;
      animation-delay: 900ms;
      animation-fill-mode: both;
      animation-iteration-count: 1;
    }
    display: flex;
  }
`

export const SocialContainer = styled.div`
  height: 112px;
  background: #fbf9f6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 16px;
  display: flex;

  @media (min-width: 1024px) {
    position: absolute;
    top: 70px;
    transition: 380ms;
    opacity: ${props => (props.dropDownOpen ? '1' : '0')};
    visibility: ${props => (props.dropDownOpen ? 'visible' : 'hidden')};
    pointer-events: ${props => (props.dropDownOpen ? 'all' : 'none')};
    z-index: -4;
    box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.15);

    .social-buttons {
      margin-left: 0px;
    }
  }

  @media (min-width: 1280px) {
    top: ${props => (props.sticky ? '80px' : '100px')};
  }
`
export const SearchImg = styled.img`
  margin: 0;
`

export const HeroImg = styled.img`
  z-index: -2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Title = styled.h1`
  position: absolute;
  left: 28px;
  bottom: 26px;
  font-size: 36px;
  color: #fbf9f6;
  letter-spacing: -0.25px;
  line-height: 100%;
  text-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
  margin: 0;
  max-width: 400px;

  @media (min-width: 1024px) {
    max-width: 700px;
    font-size: 72px;
    left: ${props =>
      props.generic || props.sticky ? '70px' : props.home ? '89px' : null};
    bottom: ${props =>
      props.generic || props.sticky ? '70px' : props.home ? '143px' : null};
  }

  @media (min-width: 1280px) {
    max-width: 700px;
    font-size: 72px;
    left: ${props =>
      props.generic || props.sticky ? '100px' : props.home ? '89px' : null};
    bottom: ${props =>
      props.generic || props.sticky ? '100px' : props.home ? '143px' : null};
  }
`

export const BallaratLogo = styled(Link)`
  position: ${props =>
    props.generic === 'true' || props.sticky === 'true'
      ? 'relative'
      : 'absolute'};
  bottom: ${props => (props.home ? '330px' : null)};
  left: ${props => (props.home ? '30px' : null)};
  display: ${props =>
    props.generic === 'true' || props.sticky === 'true' ? 'block' : 'block'};
  justify-self: start;
  padding-left: ${props =>
    props.generic === 'true'
      ? '5px'
      : props.sticky === 'true'
      ? '10px'
      : 'none'};
  pointer-events: all;

  /* ${({ mobHeader }) =>
    mobHeader &&
    `

    visibility: hidden;
  `}; */

  img {
    margin: 0;
    display: block;
    width: ${props =>
      props.sticky === 'true'
        ? '48px'
        : props.generic === 'true'
        ? '102px'
        : '102px'};
    @media (min-width: 768px) {
      width: ${props =>
        props.sticky === 'true'
          ? '48px'
          : props.generic === 'true'
          ? '120px'
          : '120px'};
    }
    @media (min-width: 1024px) {
      width: ${props =>
        props.sticky === 'true'
          ? '48px'
          : props.generic === 'true'
          ? '110px'
          : '110px'};
    }

    @media (min-width: 1280px) {
      width: ${props =>
        props.sticky === 'true'
          ? '48px'
          : props.generic === 'true'
          ? '132px'
          : '188px'};
    }
  }

  @media (min-width: 1024px) {
    ${({ mobHeader }) =>
      mobHeader &&
      `
      visibility: visible;
    `};

    justify-self: center;
    padding-left: 0;

    display: ${props =>
      props.generic === 'true' || props.sticky === 'true' ? 'block' : 'null'};
    bottom: ${props =>
      props.generic === 'true' || props.sticky === 'true'
        ? 'auto'
        : props.home === 'true'
        ? '103px'
        : null};
    left: auto;
    right: ${props =>
      props.generic === 'true' || props.sticky === 'true'
        ? 'auto'
        : props.home === 'true'
        ? '91px'
        : null};

    margin-right: ${props => (props.mobHeader ? '32px' : '')};

    ${({ mobHeader, sticky }) =>
      mobHeader &&
      sticky === 'true' &&
      `
    display: none;
  `}

    ::after {
      content: '';
      height: 71px;
      width: 1px;
      opacity: 0.3;
      background: #c0a985;
      position: absolute;
      display: none;
      top: 0;

      @media (min-width: 1024px) {
        right: -10px;
        top: -15px;
        display: ${props => props.sticky === 'true' && 'block'};
      }

      @media (min-width: 1280px) {
        right: -20px;
        top: -20px;
        height: 81px;
      }
    }
  }
`

export const Clickable = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: ${props =>
    props.sticky ? 'none' : '1px solid rgba(255,255,255,0.3)'};

  cursor: pointer;
  background: ${props => (props.isOpen ? '#FBF9F6;' : 'transparent')};

  @media (min-width: 1024px) {
    display: none;
  }

  @media print {
    display: none !important;
  }
`

export const HamburgerWrapper = styled.div`
  display: inline-block;
  width: 24px;
  height: 16px;
  position: relative;

  cursor: pointer;
`

export const FirstLine = styled.span`
  display: block;
  height: 2px;
  width: 100%;
  background: ${props =>
    props.sticky ? '#212C1F' : props.isOpen ? '#A55331' : '#FFFFFF'};
  transition: 0.4s ease;
  transform-origin: center center 0px;
  position: absolute;
  margin-top: -1px;
  transform: ${props =>
    props.isOpen
      ? 'translate3d(0, 6px, 0) rotate(45deg)'
      : 'translate3d(0, 0, 0) rotate(0deg)'};
`

export const SecondLine = styled(FirstLine)`
  transition: 0.1s ease-out;
  opacity: ${props => (props.isOpen ? 0 : 1)};
  top: 8px;
`

export const ThirdLine = styled(FirstLine)`
  transform: ${props =>
    props.isOpen
      ? 'translate3d(0, 6px, 0) rotate(-45deg)'
      : 'translate3d(0, 16px, 0) rotate(0deg)'};
  display: block;
`

export const Hashtag = styled.h3`
  font-size: 12px;
  color: #212c1f;
  letter-spacing: 0.5px;
  line-height: 28px;
  font-weight: 700 !important;
  margin: 0;
  margin-bottom: 12px;
  display: inline-block;
  text-align: ${props => (props.footer ? 'center' : 'left')};

  @media (min-width: 768px) {
    text-align: left;
    margin-bottom: ${props => (props.footer ? '14px' : '12px')};
  }
  @media (min-width: 1024px) {
    text-align: ${props => (props.footer ? 'left' : 'center')};
    color: #212c1f;
  }
`

export const SocialButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const MobileSocialContainer = styled.div`
  margin-top: 30px;
  padding-bottom: 16px;
  display: block;

  @media (min-width: 1024px) {
    display: none;
  }
`
export const IconWrapper = styled.div`
  display: flex;
  margin-left: ${props => (props.footer ? '0' : '-15px')};
  justify-content: ${props => (props.footer ? 'center' : 'flex-start')};
  margin-bottom: ${props => (props.footer ? '15px' : '0')};

  @media (min-width: 768px) {
    justify-content: flex-start;
    margin-bottom: ${props => (props.footer ? '42px' : '0')};
  }

  a {
    /* padding: 0 15px; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 53px;
    transition: 600ms;
    transition-delay: ${props => (props.fadeIn ? '100ms' : '0')};
    opacity: ${props => (props.fadeIn ? '1' : props.fade ? '0' : '1')};
    /* svg {
      fill: #fff;
      @media (min-width: 1024px) {
        fill: #212c1f;
      }
      path {
        fill: #fff;
        @media (min-width: 1024px) {
          fill: #212c1f;
        }
      }
    } */

    :nth-of-type(2) {
      transition-delay: ${props => (props.fadeIn ? '200ms' : '0')};
    }

    :nth-of-type(3) {
      transition-delay: ${props => (props.fadeIn ? '320ms' : '0')};
    }

    :nth-of-type(4) {
      transition-delay: ${props => (props.fadeIn ? '450ms' : '0')};
    }

    :hover {
      svg {
        fill: #a55331;

        path {
          fill: #a55331;
        }
      }
    }
  }
  img {
    max-height: 24px;
    margin-bottom: 0;

    :hover {
      fill: #a55331;
    }
  }
`

//navHover.js

export const HoverPosition = styled.div`
  position: absolute;
  top: 60px;
  width: 94.202%;
  transition: 80ms;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10000;
  pointer-events: ${props => !props.hovered && 'none'};
  display: ${props =>
    props.sticky ? ' none' : props.hovered ? 'block' : 'none'};

  @media (min-width: 1024px) {
    top: 70px;
  }

  @media (min-width: 1280px) {
    top: ${props =>
      props.sticky ? '80px' : props.generic ? '100px' : '100px'};
  }

  @media (min-width: 1460px) {
    width: 100%;
  }
`

export const HoverContainer = styled.div`
  position: relative;
  background: #fbf9f6;
  margin: 0;
  width: 100%;
  height: 604px;

  /* max-height: 600px; */
  padding: 45px 41px 50px 41px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 55px;
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.15);

  @media (min-width: 1280px) {
    height: 561px;
  }
`

export const SubHeader = styled.span`
  display: block;
  font-size: 28px;
  width: 100%;
  line-height: 100%;
  color: #212c1f;
  letter-spacing: -0.22px;
  font-family: Freight Big;
  text-transform: none;
  margin-bottom: 26px;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    margin: 0;
    bottom: 3px;
    margin-left: 17px;
    background: #212c1f;
    height: 1px;
    width: 100%;
    display: inline-block;
  }
`

export const ThemeContainer = styled.div`
  overflow: hidden;

  > p:first-of-type {
    font-size: 17px;
    letter-spacing: 0;
    line-height: 150%;
    padding-bottom: 33px;
    font-weight: 300;
    position: relative;
    margin: 0;
    opacity: ${props => (props.fadeInContent ? '1' : '0')};
    transition: 250ms;

    @media (min-width: 1280px) {
      font-size: 18px;
    }

    ::before {
      content: '';
      position: absolute;
      bottom: 0;
      margin: 0;
      background: #b9bab4;
      height: 1px;
      width: 100%;
      display: block;
    }
  }
`

export const ThemeGrid = styled.div`
  opacity: ${props => (props.fadeInContent ? '1' : '0')};
  transition: 250ms;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: ${props =>
    props.addColumn ? '1fr 1fr 1fr' : '1fr 1fr'};
  position: relative;
  margin-bottom: 30px;
  grid-column-gap: ${props => (props.addColumn ? '30px' : '45px')};
  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    margin: 0;
    background: #b9bab4;
    height: 1px;
    width: 100%;
    display: block;
  }
`

export const Theme = styled.div`
  position: relative;
  display: flex;
  padding: 18px 0;
  align-items: center;

  @media (min-width: 1280px) {
    padding: 20px 0;
  }

  svg {
    margin: 0;
    margin-right: 20px;
    width: 24px;
    height: 24px;
    font-size: 24px;
    flex-shrink: 0;
  }
  h4 {
    font-weight: 400;
    font-size: ${props => (props.addColumn ? '14px' : '15px')};
    letter-spacing: -0.1px;
    line-height: 100%;
    text-transform: none;
    margin: 0;

    @media (min-width: 1280px) {
      font-size: ${props => (props.addColumn ? '15px' : '16px')};
    }

    a {
      color: #212c1f !important;
      :before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      :hover {
        color: #a55331 !important;
      }
    }
  }

  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    margin: 0;
    background: #b9bab4;
    height: 1px;
    width: 300%;
    display: block;
  }
`

export const MoreOptions = styled.div`
  display: flex;
  position: absolute;
  bottom: 58px;
  left: 41px;

  @media (min-width: 1280px) {
    bottom: 48px;
  }
  h5 {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.11px;
    line-height: 20px;
    margin: 0;

    :first-of-type {
      margin-right: 68px;
      position: relative;

      ::after {
        content: '';
        position: absolute;
        margin: 0;
        margin-left: 34px;
        background: #b9bab4;
        height: 20px;
        width: 1px;
        display: inline-block;
      }
    }

    a {
      color: #212c1f;

      :hover {
        color: #a55331 !important;
      }
    }
  }
`

export const TeaserContainer = styled.div`
  overflow: hidden;
`

export const TeaserGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  padding-right: 30px;

  > div {
    opacity: ${props => (props.fadeInContent ? '1' : '0')};
    transition: 250ms;
  }
`
function newFunction() {
  return null
}
