import styled from 'styled-components'

import BallaLogo from './img/ballaratLogo.svg'
import TourismLogo from './img/tourismLogo.svg'

export const Container = styled.div`
  max-width: 1201px;
  margin: 0 auto;
  width: calc(100% - 20px);

  padding-top: 30px;
  padding-bottom: 60px;

  @media (min-width: 768px) {
    border-top: 1px solid #212c1f;

    display: grid;
    grid-template-columns: 1.7fr 1fr;
    grid-template-rows: auto auto auto;
    padding-top: 0;
    width: calc(100% - 60px);
    padding-top: 53px;
    margin-top: 32px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 2.8fr 1fr;
  }

  * a {
    :hover {
      color: #a55331;
    }
  }

  .hide-print {
    @media print {
      display: none;
    }
  }
`
export const NavWrapper = styled.ul`
  margin-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-top: 1px solid #212c1f;
  padding-top: 30px;

  li {
    padding-right: 20px;
    @media (min-width: 768px) {
      padding-right: 45px;
    }
    @media (min-width: 1024px) {
      padding-right: 55px;
    }
    @media (min-width: 1440px) {
      padding-right: 55px;
    }
  }
  a {
    text-decoration: none;
    font-weight: 500;
    font-size: 12px;
    color: #545c52;
    letter-spacing: 0;
    text-align: center;
    line-height: 36px;
    text-transform: uppercase;

    :hover {
      color: #a55331;
    }

    @media (min-width: 1440px) {
      font-size: 14px;
    }
  }

  @media (min-width: 768px) {
    border-top: none;
    grid-column-start: 1;
    grid-column-end: 1;
    justify-content: flex-start;
    padding-bottom: 0;
    padding-top: 0;
    max-width: 400px;
  }

  @media (min-width: 1024px) {
    max-width: none;
  }

  @media print {
    display: none;
  }
`

export const InfoContainer = styled.div`
  border-top: 1px solid #212c1f;
  margin-top: 30px;
  padding-top: 35px;
  padding-bottom: 35px;
  width: 100%;

  @media (min-width: 768px) {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 5;
    border-top: none;
    border-bottom: none;
    border-left: 1px solid #b9bab4;
    padding-top: 5px;
    padding-bottom: 0;
    padding-left: 37px;
    border-top: 0;
    margin-top: 0;
  }

  @media (min-width: 1024px) {
    padding-left: 52px;
  }

  p.address__heading {
    font-weight: 600;
    font-size: 12px;
    color: #545c52;
    letter-spacing: 0;
    text-align: center;
    line-height: 23px;
    margin-bottom: 0;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-weight: 600;
      font-size: 12px;
      color: #212c1f;
      letter-spacing: 0.5px;
      line-height: 1.5;
      text-align: left;
    }

    :first-child {
      padding-bottom: 25px;
      @media (min-width: 1024px) {
        padding-bottom: 25px;
      }
    }
  }

  p.address__location {
    font-weight: 500;
    font-size: 12px;
    color: #545c52;
    letter-spacing: 0;
    text-align: center;
    line-height: 23px;
    margin-bottom: 0;
    text-transform: lowercase;
    text-transform: capitalize;
    padding-top: 0;

    @media (min-width: 768px) {
      font-size: 14px;
      color: #212c1f;
      letter-spacing: -0.11px;
      line-height: 14px;
      text-align: left;
    }

    :last-child {
      padding-top: 10px;
    }
  }

  p.address__full {
    font-weight: 300;
    font-size: 12px;
    color: #49524f;
    letter-spacing: 0;
    text-align: center;
    line-height: 23px;
    text-transform: lowercase;
    text-transform: capitalize;

    @media (min-width: 768px) {
      text-align: left;
      line-height: 25px;
      padding-top: 5px;
    }
  }
`
export const FooterContainer = styled.div`
  border-top: 1px solid #212c1f;
  padding-top: 40px;
  padding-bottom: 40px;

  @media (min-width: 768px) {
    border-top: none;
    padding-top: 20px;
    padding-bottom: 0;
  }
  @media (min-width: 1024px) {
    padding-top: 0;
    padding-top: 0;
  }

  p {
    color: #49524f;
    text-align: center;
    font-size: 14px;
    margin-bottom: 0;
    @media (min-width: 768px) {
      text-align: left;

      margin-right: 30px;
    }
  }
`

export const AcknoledgementWrapper = styled.div`
  padding-top: 16px;

  @media (min-width: 1024px) {
    padding-top: 24px;
  }
`
export const InnerFooter = styled.div`
  @media (min-width: 768px) {
    width: 50%;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 0.65fr 3fr;
  }
`

export const CopyRight = styled.li`
  list-style: none;
  font-weight: 300;
  font-size: 12px;
  color: #49524f;
  letter-spacing: 0;
  text-align: center;
  line-height: 36px;

  letter-spacing: 0;

  @media (min-width: 768px) {
    text-align: left;
    line-height: 16px;
    grid-column-start: 1;
    grid-row-start: 1;
  }
`
export const FooterWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 375px;
  margin: 0 auto;

  @media (min-width: 768px) {
    grid-column-start: 1;
    grid-row-start: 2;
    padding-top: 16px;
    margin-left: 0;
  }

  @media (min-width: 1024px) {
    grid-column-start: 2;
    grid-row-start: 1;
    justify-content: flex-start;
    max-width: none;
    padding-left: 35px;
    padding-top: 0;
  }
`

export const FooterNav = styled(CopyRight)`
  font-size: 12px;
  font-weight: 500;
  color: #545c52;

  a {
    color: inherit;
    text-decoration: inherit;

    :hover {
      color: #a55331;
    }
  }

  @media (min-width: 768px) {
    margin-right: 30px;
  }
  @media (min-width: 1024px) {
    text-align: left;
    border-right: none;
    padding-left: 0;
  }
`

export const LogosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  /* max-width: 375px; */
  margin: 0 auto;

  @media (min-width: 768px) {
    justify-content: flex-start;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 3;
    margin: 0;
  }

  @media (min-width: 1024px) {
    justify-content: flex-start;
    align-items: center;
    margin: 0;
  }

  a,
  div {
    margin: 0;
    display: flex;
    align-items: center;
    margin: 0 5px;
    height: 60px;
    opacity: 1;
    transition: 160ms;
    @media (min-width: 768px) {
      margin: 0;
      margin-right: 20px;
    }
    @media (min-width: 1024px) {
      margin-right: 40px;
    }

    img {
      margin: 0;
      height: 100%;
    }
  }

  a {
    &:hover {
      opacity: 0.6;
    }
  }
`
export const Ballarat = styled.div`
  background-image: url(${BallaLogo});
  width: 140px;
  height: 45px;
  @media (min-width: 768px) {
    margin-right: 55px;
  }
`
export const Tourism = styled.div`
  background-image: url(${TourismLogo});
  width: 56px;
  height: 56px;
`

export const LogosButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  @media (min-width: 768px) {
    align-items: flex-start;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
    align-items: center;
  }

  > a {
    background: #212c1f;
    border: 1px solid #212c1f;
    color: white;
    outline: none;
    padding: 13px 15px;
    font-weight: 500;
    margin: 30px 0 10px;
    transition: 120ms;
    @media (min-width: 1280px) {
      margin: 0;
    }

    :hover {
      background: transparent;
      color: #212c1f;
    }
  }
`
