import React from 'react'

import { ReactComponent as InstagramIcon } from '../../images/instagram-nav.svg'
import { ReactComponent as FacebookIcon } from '../../images/facebook-nav.svg'
import { ReactComponent as YoutubeIcon } from '../../images/youtube-nav.svg'
import { ReactComponent as PinterestIcon } from '../../images/pinterest.svg'
import { ReactComponent as TiktokIcon } from '../../images/tiktok.svg'

import { Hashtag, IconWrapper, SocialButtonContainer } from './style'

const SocialButtons = props => {
  return (
    <SocialButtonContainer {...props}>
      <Hashtag {...props}>#VISITBALLARAT</Hashtag>

      <IconWrapper
        {...props}
        fadeIn={props.dropDownOpen}
        className="social-buttons"
      >
        <a
          aria-label="Instagram"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/visitballarat/"
        >
          <InstagramIcon />
        </a>

        <a
          aria-label="Facebook"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/VisitBallarat/"
        >
          <FacebookIcon />
        </a>
        <a
          aria-label="Tiktok"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.tiktok.com/@visitballarat"
        >
          <TiktokIcon />
        </a>
        <a
          aria-label="Youtube"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/channel/UCpxZC_QTQrKWPaWqdt2_zIA"
        >
          <YoutubeIcon />
        </a>

        <a
          aria-label="Pinterest"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.pinterest.com.au/visitballarat/"
        >
          <PinterestIcon />
        </a>
      </IconWrapper>
    </SocialButtonContainer>
  )
}

export default SocialButtons
