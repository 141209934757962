import styled, { keyframes } from 'styled-components'

const scaleIn = keyframes`
  from {
    opacity: 0;
		transform: scale(1.05)
  }

  to {
    opacity: 1;
		transform: scale(1)
  }
`

export const ParaImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  will-change: transform;
  transform: translateY(var(--ty));
  transition: transform 0.5s ease-out;
`

export const HomeParallax = styled.div`
  height: 100%;
  position: relative;
  opacity: 0;
  animation: ${scaleIn} ease-out 600ms;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; /*when the spec is finished*/
  animation-delay: 1s;

  @media(min-width: 1024px) {
    animation-delay: 2.5s;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: black;
    z-index: 2;
    opacity: 0.15;
  }

  .home__fore-img {
    will-change: transform;
    transition: transform 0.4s ease-out;
  }
`
