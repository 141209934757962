import React, { Component } from 'react'

import NavItem from './NavItem'

import { StaticQuery, graphql } from 'gatsby'

import { SiteSearch } from '../SiteSearch'
import SocialButtons from './socialButtons'

import ballaratLogoVar from '../../images/vb-medium-logo.svg'
import mobLogoWhite from '../../images/vb-logo.svg'
import mobLandingLogo from '../../images/mob-home-logo.svg'

import { ReactComponent as MagnifyingGlass } from '../../images/magnifying-glass.svg'
import { ReactComponent as CloseButton } from '../../images/white-close.svg'

import {
  NavContainer,
  NavWrapper,
  Nav,
  Clickable,
  BallaratLogo,
  InstagramDropDown,
  ToggleSearch,
  SocialContainer,
  MobileSocialContainer,
} from './style.js'

import Hamburger from './hamburger.js'

class NavBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dropDownOpen: false,
      searchOpen: false,
      isOpen: false,
      progress: 0,
      navShown: false,
      sticky: false,
      showNav: false,
      hideNav: false,
      normal: true,
      prevRatio: 0,
      prevY: 0,
    }

    this.hamburgerClick = this.hamburgerClick.bind(this)
    this.escFunction = this.escFunction.bind(this)
    this.showSticky = this.showSticky.bind(this)
    this.hideSticky = this.hideSticky.bind(this)
    this.handleToggleSearch = this.handleToggleSearch.bind(this)
    this.dropDownToggle = this.dropDownToggle.bind(this)
    this.closeDropDown = this.closeDropDown.bind(this)
    this.closeSearchMenu = this.closeSearchMenu.bind(this)
    this.handleIntersect = this.handleIntersect.bind(this)
  }

  componentWillReceiveProps() {
    if (!this.mediaQuery.matches) {
      return false
    }
    this.setState({
      isOpen: false,
    })
  }

  componentDidMount() {
    this.mediaQuery = window.matchMedia('(max-width: 1024px)')

    document.addEventListener('keydown', this.escFunction)

    this.createObserver()

    this.mediaQuery.addListener(this.hideSticky)
  }

  componentWillUnmount() {
    this.observer.unobserve(document.querySelector('#sticky-trigger'))
  }

  createObserver() {
    this.observer = new IntersectionObserver(this.handleIntersect, {
      root: null,
      rootMargin: '0px',
      threshold: [0.0, 0.2, 0.4, 0.6, 0.8, 1.0],
    })

    this.observer.observe(document.querySelector('#sticky-trigger'))
  }

  handleIntersect(entries) {
    if (this.mediaQuery.matches) {
      return false
    }

    entries.map(entry => {
      const currentY = entry.boundingClientRect.y
      const currentRatio = entry.intersectionRatio
      const isIntersecting = entry.isIntersecting

      if (currentY < this.state.prevY) {
        if (currentRatio > this.state.prevRatio && isIntersecting) {
          this.showSticky()
        } else return null
      } else if (currentY > this.state.prevY) {
        if (currentRatio < this.state.prevRatio && isIntersecting) {
          return null
        } else {
          this.hideSticky()
        }
      }

      this.setState({
        prevRatio: currentRatio,
        prevY: currentY,
      })
    })
  }

  escFunction(event) {
    if (event.keyCode === 27 && this.state.searchOpen) {
      this.setState({
        searchOpen: false,
      })
    }

    if (event.keyCode === 27 && this.state.dropDownOpen) {
      this.setState({
        dropDownOpen: false,
      })
    }
  }

  closeDropDown(event) {
    if (
      !this.socialMenu.contains(event.target) &&
      this.socialMenu &&
      this.state.dropDownOpen
    ) {
      this.setState({ dropDownOpen: false })
    }
    document.removeEventListener('click', this.closeDropDown)
  }

  closeSearchMenu(event) {
    if (
      !this.searchMenu.contains(event.target) &&
      this.searchMenu &&
      this.state.searchOpen
    ) {
      this.setState({ searchOpen: false })
    }
    document.removeEventListener('click', this.closeSearchMenu)
  }

  hamburgerClick() {
    this.setState({
      searchOpen: false,
      isOpen: !this.state.isOpen,
    })
  }

  handleToggleSearch(event) {
    this.setState(
      {
        searchOpen: true,
        isOpen: false,
        dropDownOpen: false,
      },
      () => document.addEventListener('click', this.closeSearchMenu)
    )
  }

  dropDownToggle(event) {
    this.setState(
      {
        dropDownOpen: true,
        searchOpen: false,
      },
      () => document.addEventListener('click', this.closeDropDown)
    )
  }

  showSticky() {
    clearTimeout(this.showNavId)
    this.setState(
      {
        hideNav: false,
        normal: false,
        sticky: true, // Madeofballarat set this to false
        navShown: true,
        dropDownOpen: false,
        searchOpen: false,
      },
      () => this.nextStep()
    )
  }

  hideSticky() {
    clearTimeout(this.showNavId)
    this.setState(
      {
        hideNav: true,
        showNav: false,
        navShown: false,
        dropDownOpen: false,
        searchOpen: false,
      },
      () => this.nextStep()
    )
  }

  nextStep() {
    this.showNavId = setTimeout(() => {
      if (this.state.navShown) {
        this.setState({ showNav: true })
        // console.log('movedown')
      } else if (!this.state.navShown) {
        this.setState({ normal: true, sticky: false, hideNav: false })
        return null
      }
    }, 400)
  }

  render() {
    // console.log('<><><>:', this.props.mobHeader)
    return (
      <NavContainer sticky={this.state.sticky} {...this.props}>
        <div
          ref={searchElement => {
            this.searchMenu = searchElement
          }}
        >
          <SiteSearch
            sticky={this.state.sticky}
            searchOpen={this.state.searchOpen}
            closeSearchMenu={this.closeSearchMenu}
          />
        </div>

        <NavWrapper
          className={this.props.home && 'home'}
          normal={this.state.normal}
          sticky={this.state.sticky}
          showNav={this.state.showNav}
          hideNav={this.state.hideNav}
          {...this.props}
        >
          {(this.props.generic || this.state.sticky) && (
            <BallaratLogo
              aria-label="Visit Ballarat"
              sticky={this.state.sticky ? 'true' : 'false'}
              generic={this.props.generic ? 'true' : 'false'}
              mobLandingTop
              to={this.props.mobHeader ? '/made-of-ballarat' : '/'}
            >
              {this.props.mobHeader ? (
                <img
                  src={this.state.sticky ? ballaratLogoVar : mobLandingLogo}
                  alt=""
                />
              ) : (
                <img
                  src={this.state.sticky ? ballaratLogoVar : mobLogoWhite}
                  alt=""
                />
              )}
            </BallaratLogo>
          )}

          <Nav
            sticky={this.state.sticky}
            {...this.props}
            isOpen={this.state.isOpen}
            onMouseEnter={this.initialHover}
            onMouseLeave={this.finalUnhover}
          >
            <ul>
              {/* Regular Nav Items */}
              {!this.props.mobHeader &&
                this.props.menudata.map((item, index) => {
                  if (!item.avMenu.pageLink) return false

                  return (
                    <NavItem
                      className={this.props.home && 'home'}
                      closeSearchMenu={this.closeSearchMenu}
                      key={index}
                      home={this.props.home}
                      mobileClick={this.hamburgerClick}
                      sticky={this.state.sticky}
                      label={item.avMenu.label}
                      heading={item.avMenu.heading}
                      description={item.avMenu.description}
                      path={item.avMenu.pageLink.uri}
                      megaMenu={item.avMenu.megaMenuItems}
                      featuredArticles={item.avMenu.dontMissThese}
                    />
                  )
                })}

              {/* MOB Nav Items */}
              {this.props.mobHeader &&
                this.props.mobMenuData.nodes.map((item, index) => {
                  console.log(this.props.generic, this.props.mobHeader)
                  return (
                    <NavItem
                      className={this.props.home && 'home'}
                      closeSearchMenu={this.closeSearchMenu}
                      key={index}
                      home={this.props.home}
                      mobileClick={this.hamburgerClick}
                      sticky={this.state.sticky}
                      label={item.label}
                      path={item.path}
                      mobHeader={this.props.mobHeader}
                    />
                  )
                })}

              {!this.props.home && (
                <NavItem
                  className={this.props.home ? 'home ' : 'back--visit'}
                  closeSearchMenu={this.closeSearchMenu}
                  home={this.props.home}
                  mobileClick={this.hamburgerClick}
                  sticky={this.state.sticky}
                  label={'Back to Visit Ballarat'}
                  path={'/'}
                  back={true}
                />
              )}
            </ul>

            <MobileSocialContainer>
              <SocialButtons />
            </MobileSocialContainer>
          </Nav>

          {!this.props.mobHeader && (
            <>
              <InstagramDropDown
                className={this.props.home && 'home'}
                sticky={this.state.sticky}
                dropDownOpen={this.state.dropDownOpen}
                {...this.props}
              >
                <button
                  aria-label="Social Accounts"
                  onClick={this.dropDownToggle.bind(this)}
                >
                  <svg
                    width="13px"
                    height="8px"
                    viewBox="0 0 13 8"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      id="Symbols"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                      opacity="0.5"
                    >
                      <g
                        id="HEADER-NO-LOGO"
                        transform="translate(-1191.000000, -46.000000)"
                        fill="#F6EFE9"
                      >
                        <g id="HEADER">
                          <g
                            id="SOCIAL"
                            transform="translate(1191.000000, 38.000000)"
                          >
                            <g
                              id="small-down"
                              transform="translate(0.000000, 8.000000)"
                            >
                              <path
                                d="M6.586,7.39527974 C6.37882439,7.39527974 6.17164877,7.31597033 6.01383922,7.15816078 L0.586,1.73032155 L1.73032155,0.586 L6.586,5.44167845 L11.4416784,0.586 L12.586,1.73032155 L7.15816078,7.15816078 C7.00035123,7.31597033 6.79317561,7.39527974 6.586,7.39527974 Z"
                                id="Path"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>

                  <svg
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <g fill="#212C1F">
                        <path d="M12 2.162c3.204 0 3.584.012 4.849.07 1.366.062 2.633.336 3.608 1.311.975.975 1.249 2.242 1.311 3.608.058 1.265.07 1.645.07 4.849s-.012 3.584-.07 4.849c-.062 1.366-.336 2.633-1.311 3.608-.975.975-2.242 1.249-3.608 1.311-1.265.058-1.645.07-4.849.07s-3.584-.012-4.849-.07c-1.366-.062-2.633-.336-3.608-1.311-.975-.975-1.249-2.242-1.311-3.608-.058-1.265-.07-1.645-.07-4.849s.012-3.584.07-4.849c.062-1.366.336-2.633 1.311-3.608.975-.975 2.242-1.249 3.608-1.311 1.265-.058 1.645-.07 4.849-.07zM12 0C8.741 0 8.332.014 7.052.072c-1.95.089-3.663.567-5.038 1.942C.639 3.389.161 5.102.072 7.052.014 8.332 0 8.741 0 12c0 3.259.014 3.668.072 4.948.089 1.95.567 3.663 1.942 5.038 1.375 1.375 3.088 1.853 5.038 1.942C8.332 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 1.95-.089 3.663-.567 5.038-1.942 1.375-1.375 1.853-3.088 1.942-5.038.058-1.28.072-1.689.072-4.948 0-3.259-.014-3.668-.072-4.948-.089-1.95-.567-3.663-1.942-5.038C20.611.639 18.898.161 16.948.072 15.668.014 15.259 0 12 0z" />
                        <path d="M12 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324zM12 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                        <circle cx="18.406" cy="5.594" r="1.44" />
                      </g>
                    </g>
                  </svg>
                </button>

                <SocialContainer
                  sticky={this.state.sticky}
                  {...this.props}
                  dropDownOpen={this.state.dropDownOpen}
                  ref={element => {
                    this.socialMenu = element
                  }}
                >
                  <SocialButtons fade dropDownOpen={this.state.dropDownOpen} />
                </SocialContainer>
              </InstagramDropDown>

              <ToggleSearch
                aria-label="Search"
                home={this.props.home}
                className={this.props.home && 'home'}
                sticky={this.state.sticky}
                searchOpen={this.state.searchOpen}
                onClick={this.handleToggleSearch.bind(this)}
              >
                <div className={this.props.home && 'home'}>
                  {this.state.searchOpen ? (
                    <CloseButton />
                  ) : (
                    <MagnifyingGlass sticky={this.state.sticky} />
                  )}
                </div>
              </ToggleSearch>
            </>
          )}

          {this.props.mobHeader && !this.state.sticky && (
            <BallaratLogo
              aria-label="Visit Ballarat"
              sticky={this.state.sticky ? 'true' : 'false'}
              generic={this.props.generic ? 'true' : 'false'}
              mobHeader={this.props.mobHeader}
              to="/"
            >
              <img
                src={this.state.sticky ? ballaratLogoVar : mobLogoWhite}
                alt=""
              />
            </BallaratLogo>
          )}

          <Clickable
            sticky={this.state.sticky}
            {...this.props}
            isOpen={this.state.isOpen}
            onClick={() => this.hamburgerClick()}
          >
            <Hamburger
              sticky={this.state.sticky}
              {...this.props}
              isOpen={this.state.isOpen}
            />
          </Clickable>
        </NavWrapper>
      </NavContainer>
    )
  }
}

// export default NavBar;

export default props => (
  <StaticQuery
    query={graphql`
      query {
        unearthMenuItems: wpMenu(slug: { eq: "made-of-ballarat" }) {
          name
          menuItems {
            nodes {
              label
              connectedNode {
                node {
                  ... on WpPage {
                    status
                    templateSlug
                  }
                }
              }
              path
            }
          }
        }

        menuItems: allWpVbMenuItem(sort: { fields: menuOrder, order: ASC }) {
          nodes {
            menuOrder
            avMenu {
              label
              pageLink {
                ... on WpPage {
                  uri
                }
              }
              heading
              description
              megaMenuItems {
                label
                pageLink {
                  ... on WpPage {
                    uri
                  }
                }
                pageLinkCustom
                menuIcon
              }
              dontMissThese {
                title
                description
                image {
                  sourceUrl
                  srcSet
                }
                pageLinkCustom
                pageLink {
                  ... on WpPost {
                    uri
                  }
                  ... on WpEvent {
                    uri
                  }
                  ... on WpPage {
                    uri
                  }
                  ... on WpPost {
                    uri
                  }
                  ... on WpOperator {
                    uri
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ menuItems, unearthMenuItems }) => (
      <NavBar
        menudata={menuItems.nodes}
        mobMenuData={unearthMenuItems.menuItems}
        {...props}
      />
    )}
  />
)
