import styled from 'styled-components';

export const SizeContainer = styled.div`
	position: relative;
	width: 100%;
	margin-top: 10px;
	z-index: 1;
	pointer-events: none;
	@media (min-width: 1024px) {
		margin-top: 20px;
	}

	@media (min-width: 1164px) {
		position: absolute;
		width: 1154px;
		left: 10px;
		right: 0;
		margin-left: calc(((100% - 1200px) / 2) - 10px);
		transform: translateY(100%);
		bottom: ${(props) => (props.headerImage ? '-95px' : '-60px')};
	}

	@media (min-width: 1280px) {
		left: 0;
	}
`;

export const Container = styled.div`
	display: flex;
	justify-content: flex-start;
	width: calc(100% - 20px);
	max-width: 780px;
	margin: 0 auto;
	margin-top: 20px;
	pointer-events: initial;

	@media (min-width: 1164px) {
		max-width: 170px;
		display: block;
		position: relative;
		border-right: 1px solid #b9bab4;
		padding-right: 30px;
		margin: 0;
		padding-bottom: 25px;
		background: #fbf9f6;
	}
`;
export const Pic = styled.div`
	width: 48px;
	height: 48px;
	margin-bottom: 0;

	@media (min-width: 1164px) {
		display: block;
		margin-left: calc(100% - 64px);
		width: 64px;
		height: 64px;
		margin-bottom: 20px;
		text-align: right;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	margin-left: 20px;
	width: 80%;

	@media (min-width: 1164px) {
		display: block;
		text-align: right;
		margin-left: 0;
		width: 100%;
	}

	h4 {
		font-family: Freight Big;
		font-size: 16px;
		color: #212c1f;
		letter-spacing: -0.12px;
		line-height: 122%;
		text-transform: none;
		padding-right: 8px;
		margin-bottom: 0;
		border-right: 1px solid #b9bab4;

		@media (min-width: 1164px) {
			font-size: 18px;
			letter-spacing: -0.14px;
			text-align: right;
			border-right: none;
			padding-right: 0;
			margin-bottom: 15px;
		}
	}

	h6 {
		font-weight: 300;
		font-size: 13px;
		color: #545c52;
		letter-spacing: 0;
		line-height: 12px;
		padding-left: 8px;
		margin-bottom: 0;
		text-transform: none;

		@media (min-width: 1164px) {
			text-align: right;
			line-height: 20px;
			margin-bottom: 5px;
		}
	}

	p {
		font-weight: 300;
		font-size: 12px;
		color: #545c52;
		letter-spacing: 0;
		line-height: 14px;
		margin-bottom: 0;

		@media (min-width: 1164px) {
			font-size: 14px;
			letter-spacing: 0;
			text-align: right;
			line-height: 20px;
		}
	}

	span {
		margin-left: 6px;
		display: inline;
		margin-top: 2px;
		font-weight: 400;
		color: #212c1f;
		/* text-transform: lowercase; */
		::first-letter {
			text-transform: capitalize;
		}

		@media (min-width: 1164px) {
			margin-left: 0px;
			display: block;

			font-size: 14px;
			letter-spacing: 0;
			text-align: right;
			line-height: 20px;
		}
	}
`;
export const InnerWrapper = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	flex-direction: row;
	padding-bottom: 10px;
	margin-bottom: 15px;

	@media (min-width: 1164px) {
		display: block;
	}

	::before {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 1px;
		background-color: #b9bab4;

		@media (min-width: 1164px) {
			width: 90px;
		}
	}
`;
