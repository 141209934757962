import React, { Component } from 'react'
import { AvenueImg } from '../AvenueImg'
import { Link } from 'gatsby'

import {
  HeroImgWrapper,
  BallaratLogo,
  Title,
  GetStarted,
  HeaderContainer,
  CategoryLabel,
  TitleWrapper,
  HeaderText,
  ImageContainer,
  ImageOverlay,
  StickyTrigger,
  IconWrapper,
  LinkWrapper,
  LinkIcon,
} from './style.js'
import { NavBar } from '../NavBar/index.js'
import { HomeHeaderParallax } from '../HomeHeaderParallax'
import { PostDetails } from '../PostDetails'

import { ReactComponent as Pin } from '../../images/pin.svg'
import { ReactComponent as HomeIcon } from '../../images/home-icon.svg'
import { ReactComponent as HeaderDots } from '../../images/header-dots.svg'
import mobLogoWhite from '../../images/vb-logo--white.png'

// import { homeIcon } from 'static/images/home-icon.svg'

class HeaderVb extends Component {
  constructor(props) {
    super(props)

    this.state = {
      slideUp: false,
    }
  }

  componentDidMount() {}
  render() {
    return (
      <HeaderContainer {...this.props}>
        {/* {this.props.home && <NewBrand slideUp={this.state.slideUp} />} */}
        <NavBar
          mobHeader={this.props.mobHeader}
          home={this.props.home}
          generic={this.props.generic}
          headerImage={this.props.headerImage}
          headerTitle={this.props.headerTitle}
        />
        <StickyTrigger id="sticky-trigger" />

        <ImageContainer {...this.props}>
          <HeroImgWrapper {...this.props}>
            {this.props.headerImage && (
              <AvenueImg
                className={this.props.home ? 'home__img' : ''}
                fluid={{
                  ...this.props.headerImage,
                  sizes: '100vw',
                }}
                alt=""
              />
            )}
            {this.props.headerImage && <ImageOverlay {...this.props} />}
          </HeroImgWrapper>
          {this.props.home && (
            <BallaratLogo aria-label="Visit Ballarat" {...this.props} href="/">
              <img src={mobLogoWhite} alt="" />
            </BallaratLogo>
          )}

          {this.props.home && (
            <GetStarted {...this.props} showMobile={this.props.homeText}>
              {this.props.homeText && (
                <LinkIcon
                  to={this.props.homeLink}
                  aria-label={`Background image by ${this.props.homeText}`}
                >
                  <LinkWrapper>
                    <IconWrapper>
                      <Pin />
                    </IconWrapper>
                    <h2>{this.props.homeText}</h2>
                  </LinkWrapper>
                </LinkIcon>
              )}
              {!this.props.homeText && <h5>GET STARTED</h5>}
            </GetStarted>
          )}
          {(this.props.headerTitle || this.props.home) && (
            <TitleWrapper {...this.props}>
              {(this.props.categories && this.props.categories.length) ||
              this.props.breadcrumb ? (
                <CategoryLabel {...this.props}>
                  <Link to="/" aria-label="Home">
                    <HomeIcon />
                  </Link>

                  <HeaderDots
                    style={{
                      fill: this.props.headerImage ? '#fff' : '#E0D6C8',
                    }}
                  />
                  {this.props.breadcrumb && (
                    <Link
                      className="text"
                      to={
                        this.props.breadcrumb.slug
                          ? this.props.breadcrumb.slug
                          : '/'
                      }
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: this.props.breadcrumb.name,
                        }}
                      />
                    </Link>
                  )}
                </CategoryLabel>
              ) : null}

              {this.props.home ? (
                <>
                  <Title {...this.props} hasLink={this.props.homeText}>
                    <span className="anim-1" data-letters="SO MUCH TO BE">
                      SO MUCH TO BE
                    </span>
                    <br />
                    <span className="anim-2" data-letters="DISCOVERED">
                      DISCOVERED
                    </span>
                  </Title>
                </>
              ) : (
                <Title
                  {...this.props}
                  dangerouslySetInnerHTML={{
                    __html: this.props.headerTitle,
                  }}
                />
              )}
            </TitleWrapper>
          )}
        </ImageContainer>

        {this.props.generic && this.props.user && (
          <PostDetails {...this.props} />
        )}
        {(this.props.subcategory || this.props.itinerary) && (
          <HeaderText>
            <p>{this.props.headerText}</p>
          </HeaderText>
        )}
      </HeaderContainer>
    )
  }
}

export default HeaderVb
