import React, { Component } from 'react'

import { ReactComponent as Arrow } from '../../images/arrow-right.svg'
import { SocialButtons } from '../NavBar'
import { Formik } from 'formik'
import axios from 'axios'
import {
  Container,
  SignUpContainer,
  Header,
  Sent,
  Form,
  Button,
  ErrorMessage,
  SocialContainter,
} from './style.js'

class Footer extends Component {
  render() {
    return (
      <Container unearth={this.props.unearth}>
        <SignUpContainer unearth={this.props.unearth} >
          <Header unearth={this.props.unearth}>
            <h4>Looking for the best travel ideas?</h4>
            <h1>Get our curated travel tips</h1>

            <Formik
              initialValues={{ email: '' }}
              validateOnChange={false}
              validate={values => {
                let errors = {}

                if (!values.email) {
                  errors.email = 'Please enter a valid email address'
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Please enter a valid email address'
                }
                return errors
              }}
              onSubmit={(values, { setSubmitting }) => {

								setTimeout(()=>{

                setSubmitting(true)
								}, 400)

								const { email } = values

                axios
                  .post(
                    'https://au0i6l6nx7.execute-api.ap-southeast-2.amazonaws.com/mob/madeofballarat-serverless-dev-signup',
                    {
                      email: email,
                    },
                    {
                      headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'MB9xd0PIGk4evIiiDa4KH70iMtfPpNz89Pn2WNr3',
                      },
                    }
                  )
                  .then(function(response) {
                    console.log(response)
                  })
                  .catch(function(error) {
                    console.log(error)
                  })
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                validateForm,
              }) => (
                <Form
                  showError={errors.email}
                  onSubmit={handleSubmit}
                  disabled={isSubmitting}
                >
                  <Sent disabled={isSubmitting}>
                    Thanks for subscribing! <span>W</span>e'll be in touch soon.
                  </Sent>

                  <input
                    aria-label="Enter your email"
                    type="text"
                    name="email"
                    spellCheck="false"
                    placeholder="Enter your email…"
                    onChange={handleChange}
                    value={values.email}
                  />
                  <Button
                    aria-label="Submit"
                    type="submit"
                    disabled={isSubmitting}
                    onSubmit={handleSubmit}
                    onClick={() => {
                      validateForm()
                    }}
                  >
                    <Arrow />
                  </Button>

                  <ErrorMessage name="email">{errors.email}</ErrorMessage>
                </Form>
              )}
            </Formik>
          </Header>
        </SignUpContainer>
      
      </Container>
    )
  }
}

export default Footer
