import React from 'react'

import { Link as GatsbyLink } from 'gatsby';
import { checkSite } from './helpers';

const FancyLink = ({ children, to, activeClassName, partiallyActive,openInNewTab, ...other }) => {

	const internal = /^\/(?!\/)/.test(to);
	
	if (internal && !openInNewTab) {
		// console.log(to)
		return (
		
			<GatsbyLink to={to} activeClassName={activeClassName} partiallyActive={partiallyActive} {...other}>
				{children}
			</GatsbyLink>
		);
	}
	return (
		<a target="_blank" href={checkSite(to)} {...other}>
			{children}
		</a>
	);
};

export default FancyLink;
