import React from 'react';
import { IconsWrapper } from './style.js';
import { hasIcon } from '../../utils/NewCategoryicons'
import Icon from './Icon';

const Icons = (props) => {
	// console.log(props.categories)

	return (
		<IconsWrapper>
		{((props.search || props.event  || props.subcategory || props.carousel) && props.categories) && props.categories.slice(0, props.carousel ? 3 : 2).map((category, index) => (
		
			//if icon exists, render the icon component
			 hasIcon(category) && <Icon {...props} key={index} desc={category} />
		))}



	</IconsWrapper>
	)

}

export default Icons;
