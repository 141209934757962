import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import parse from 'html-react-parser'

import SignUp from '../components/SignUp/index'
import Footer from '../components/Footer/Index'
import { QuickLinks } from '../components/QuickLinks'
import { HeaderVb } from './HeaderVb'
import './layout.css'
import { theme } from '../utils/theme.js'

// import UnearthHeader from "../components/UnearthHeader/unearthHeader.js"

// Import Fonts

import '../fonts/freight-big-bold/stylesheet.css'
import '../fonts/maisonneue-demi/stylesheet.css'
import '../fonts/maisonneue-light/stylesheet.css'
import '../fonts/maisonneueextended-black/stylesheet.css'

// Unearth Fonts

import '../fonts/unearth/reforma1918-blanca/stylesheet.css'
import '../fonts/unearth/marsh-stencil/stylesheet.css'
import '../fonts/unearth/valuco-base/stylesheet.css'
import { FavouritesProvider } from '../utils/useFavourites'
import FavouriteCart from './FavouriteButton/FavouriteCart'
import { Helmet } from 'react-helmet'

const Layout = ({
  page,
  unearth,
  breadcrumb,
  mobHeader,
  subcategory,
  headerText,
  date,
  categories,
  primaryCat,
  user,
  home,
  generic,
  headerImage,
  headerTitle,
  children,
  unearthTheme,
  titleGradient,
  footerScripts,
  homeLink,
  homeText,
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <FavouritesProvider>
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/favicon-16x16.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/images/favicon-32x32.png"
        />
        <div
          style={{
            paddingTop: 0.1,
          }}
        >
          <PreviewCheck />
          <ThemeProvider theme={theme}>
            <HeaderVb
              page={page}
              mobHeader={mobHeader}
              breadcrumb={breadcrumb}
              subcategory={subcategory}
              headerText={headerText}
              date={date}
              categories={categories}
              primaryCat={primaryCat}
              user={user}
              home={home}
              headerImage={headerImage}
              titleGradient={titleGradient}
              headerTitle={headerTitle}
              generic={generic}
              homeLink={homeLink}
              homeText={homeText}
            />
          </ThemeProvider>
        </div>

        <div
          style={{
            margin: `0 auto`,
            maxWidth: 'none',
            padding: `0 0px`,
            paddingTop: 0,
          }}
        >
          <ThemeProvider theme={unearthTheme || theme}>
            <div>{children}</div>
            <FavouriteCart />
          </ThemeProvider>

          <Footer />
        </div>
        <QuickLinks />

        {footerScripts && parse(footerScripts)}
      </FavouritesProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hello: PropTypes.string,
}

export default Layout

class PreviewCheck extends React.Component {
  componentDidMount() {}

  render() {
    return <></>
  }
}
