import React from 'react'

import {
  ContainerSearchBackground,
  InnerContainerSearchBackground,
  ArrowImage,
  TitleSearchBackground,
  SubTitleSearchBackground,
} from './styleSearchBackground'

export default function SearchBackground(props) {
  return (
    <ContainerSearchBackground
      value={props.value}
      everythingElse={props.everythingElse}
    >
      <InnerContainerSearchBackground>
        <TitleSearchBackground>
          <ArrowImage show={props.show} />
          Start typing to search
        </TitleSearchBackground>

        <SubTitleSearchBackground>
          You can also hit “Enter” on your keyboard to submit your query.
        </SubTitleSearchBackground>
      </InnerContainerSearchBackground>
    </ContainerSearchBackground>
  )
}
