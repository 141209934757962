import React from 'react'
import { GetCatIcon, hasIcon } from '../../utils/NewCategoryicons';

import { OtherResult } from './style'

export default ({ title, url, content, types }) => {


  // console.log(types)
  if(types && types.length > 3) {
    types = types.slice(types.length - 3, types.length );

  }

  return (
    <OtherResult to={url}>
      <div className='types'>
        {
          types && types.map(type => (hasIcon(type) && <GetCatIcon key={type} category={type} />))
        }
      </div>
      <div className='content'>
        <h4 dangerouslySetInnerHTML={{__html: title }} />
        <p dangerouslySetInnerHTML={{__html: content }}/>
      </div>
    </OtherResult>
  )
}