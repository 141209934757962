import React from 'react'
import { ReactComponent as Bed } from '../images/svg-sprite/SVG/bed.svg'
import { ReactComponent as Beer } from '../images/svg-sprite/SVG/beer.svg'
import { ReactComponent as Calendar } from '../images/svg-sprite/SVG/calendar.svg'
import { ReactComponent as Caravan } from '../images/svg-sprite/SVG/caravan.svg'
import { ReactComponent as CoffeeCup } from '../images/svg-sprite/SVG/coffee-cup.svg'
import { ReactComponent as Concierge } from '../images/svg-sprite/SVG/concierge.svg'
import { ReactComponent as Donut } from '../images/svg-sprite/SVG/donut.svg'
import { ReactComponent as Exhibitions } from '../images/svg-sprite/SVG/exhibitions.svg'
import { ReactComponent as Festivals } from '../images/svg-sprite/SVG/festival.svg'
import { ReactComponent as FoodBowl } from '../images/svg-sprite/SVG/food-bowl.svg'
import { ReactComponent as Grapes } from '../images/svg-sprite/SVG/grapes.svg'
import { ReactComponent as MapIcon } from '../images/svg-sprite/SVG/map.svg'

import { ReactComponent as HouseBig } from '../images/svg-sprite/SVG/house-big.svg'
import { ReactComponent as Markers } from '../images/svg-sprite/SVG/markers.svg'
import { ReactComponent as KnifeFork } from '../images/svg-sprite/SVG/knife-fork.svg'
import { ReactComponent as LiveMusic } from '../images/svg-sprite/SVG/live-music.svg'
import { ReactComponent as Page } from '../images/svg-sprite/SVG/page.svg'
import { ReactComponent as Palette } from '../images/svg-sprite/SVG/palette.svg'
import { ReactComponent as Performing } from '../images/svg-sprite/SVG/performing.svg'
import { ReactComponent as Plant } from '../images/svg-sprite/SVG/plant.svg'
import { ReactComponent as Star } from '../images/svg-sprite/SVG/star.svg'
import { ReactComponent as RowBoat } from '../images/svg-sprite/SVG/row-boat.svg'
import { ReactComponent as Strawberry } from '../images/svg-sprite/SVG/strawberry.svg'
import { ReactComponent as Tennis } from '../images/svg-sprite/SVG/tennis.svg'
import { ReactComponent as Umbrella } from '../images/svg-sprite/SVG/umbrella.svg'
import { ReactComponent as Workshops } from '../images/svg-sprite/SVG/workshops.svg'
import { ReactComponent as Fitness } from '../images/fitness-icon.svg'
import { ReactComponent as Info } from '../images/info-icon.svg'
import { ReactComponent as ShoppingBag } from '../images/svg-sprite/SVG/shopping-bag.svg'

// prettier-ignore

const catmap = {

  'calendar': {icon: Calendar },
  'page': {icon: Page },
  'fitness': {icon: Fitness },
  'info': {icon: Info },


  //misc
  "See &amp; Do" : {icon: MapIcon, link: '/things-to-do' },



  //food-drink
  'Restaurants': {icon: FoodBowl, link: '/food-drink/restaurants'},
  'Wineries': {icon: Grapes, link: '/food-drink/wineries'},
  'Food &amp; Drink': {icon: KnifeFork, link: '/food-drink'},
  'Cafes': {icon: CoffeeCup, link: '/food-drink/cafes'},
  'Pubs &amp; bars': {icon: Beer, link: '/food-drink/pubs-and-bars'},

  //'Breweries'
  //'Local Producers'

  //places-to-stay
  'Holiday houses': {icon: HouseBig, link: '/places-to-stay/holiday-houses'},
  'Places to Stay': {icon: HouseBig, link: '/places-to-stay/holiday-houses'},
  'Hotels': {icon: Concierge, link: '/places-to-stay/hotels'},
  'Motels': {icon: Bed, link: '/places-to-stay/motels-backpackers-hostels'},
  'Bed &amp; Breakfasts': {icon: Bed, link: '/places-to-stay/bed-breakfasts-and-cottages'},
  'Resorts': {icon: Umbrella, link: '/places-to-stay/resorts-retreats'},
  'Caravan Parks': {icon: Caravan, link: '/places-to-stay/caravan-parks'},

  //things-to-do
  'Art &amp; culture': {icon: Palette, link: '/things-to-do/art-culture'},
  'Recreation &amp; outdoor activities': {icon: RowBoat, link: '/things-to-do/recreation-outdoor-activities/'},
  'Attractions': {icon: Star, link: '/things-to-do/experience-our-top-attractions/'},
  'Family Fun' : {icon: Donut, link: '/things-to-do/family-fun'},
  'Community Event' : {icon: LiveMusic, link: '/whats-on/?c=community-event'},
  'Makers &amp; Growers': {icon: Plant, link: '/things-to-do/makers-growers/'},
  'Itineraries': {icon: Markers, link: '/things-to-do/itineraries'},
  "Shop &amp; relax" :  {icon: ShoppingBag, link: '/things-to-do/shop-relax/'},

  //events dont require a link
  'Markets': {icon: Strawberry, link: '/whats-on/?c=markets'},
  'Sports': {icon: Tennis, link: '/whats-on/?c=sports'},
  'Sport': {icon: Tennis, link: '/whats-on/?c=sport'},
  'Exhibitions': {icon: Exhibitions, link: '/whats-on/?c=exhibitions'},
  'Exhibitions & Shows': {icon: Exhibitions, link: '/whats-on/?c=exhibitions-and-shows'},
  'Festivals': {icon: Festivals, link: '/whats-on/?c=festivals'},
  'Live music': {icon: LiveMusic, link: '/whats-on/?c=live-music'},
  'Workshops': {icon: Workshops, link: '/whats-on/?c=workshops'},
  'Workshops & Talks': {icon: Workshops, link: '/whats-on/?c=workshops-and-talks'},
  'Performing arts': {icon: Performing, link: '/whats-on/?c=performing-arts'},
  'Concerts & Performances': {icon: Performing, link: '/whats-on/?c=concerts-and-performances'},
  'Food &amp; wine': {icon: KnifeFork, link: '/whats-on/?c=food-and-wine'},
  'Food & Wine': {icon: KnifeFork, link: '/whats-on/?c=food-and-wine'},
}

export const hasIcon = category => {
  return !!catmap[category]
}

export const GetCatIcon = ({ category }) => {
  return catmap[category] ? React.createElement(catmap[category].icon) : false
}

export const getCatLink = category => {

  return catmap[category] ? catmap[category].link : false
}
