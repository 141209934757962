import React from 'react';
import { Link } from 'gatsby';
import { IconWrap } from './style.js';
import { GetCatIcon, getCatLink } from '../../utils/NewCategoryicons';

const Icon = (props) => {
	return (
		<IconWrap {...props}>
			<Link to={props.desc ? getCatLink(props.desc) : '/'}>
				<GetCatIcon category={props.desc} />
			</Link>

			<div>
				<h2 dangerouslySetInnerHTML={{ __html: props.desc }} />
			</div>
		</IconWrap>
	);
};

export default Icon;
