import moment from 'moment'
import { sortBy } from 'lodash'

const avenueSort = myArray => {
  return myArray.sort(function compare(a, b) {
    var dateA = moment(a.date, 'DD-MM-YYYY').unix()
    var dateB = moment(b.date, 'DD-MM-YYYY').unix()
    return dateA - dateB
  })
}

const dateLabel = dates => {
  const DAY_UNIT_IN_SECONDS = 24 * 3600
  const secondsSinceEpoch = Math.round(Date.now() / 1000)

  // MULTIPLE Dates
  if (dates.length > 1) {
    let sorted = dates[0].date ? avenueSort(dates) : sortBy(dates)

    let from, to

    // If date prop is included then format is 23-12-2001
    if (sorted[0].date) {
      from = moment(sorted[0].date || sorted[0], 'DD-MM-YYYY').format(
        'DD MMM YYYY'
      )
      to = moment(
        sorted[sorted.length - 1].date || sorted[sorted.length - 1],
        'DD-MM-YYYY'
      ).format('DD MMM YYYY')

      const startDateEpochSeconds =
        moment(sorted[0].date, 'DD-MM-YYYY').valueOf() / 1000

      if (sorted.length > 10 && secondsSinceEpoch > startDateEpochSeconds) {
        return `Until ${to}`
      }

      return `${from} - ${to}`
    } else {
      // If NO date prop then date is an array of unix timestamps (epoch seconds)

      from = moment.unix(sorted[0]).format('DD MMM YYYY')
      to = moment.unix(sorted[sorted.length - 1]).format('DD MMM YYYY')

      const diffInMilliseconds = sorted[sorted.length - 1] - sorted[0]
      const diffInDays = diffInMilliseconds / DAY_UNIT_IN_SECONDS

      if (diffInDays > 10 && secondsSinceEpoch > sorted[0]) {
        return `Until ${to}`
      }
    }

    return `${from} - ${to}`
  }

  // SINGLE Date
  if (dates.length === 1) {
    if (dates[0].date) {
      return moment(dates[0].date, 'DD-MM-YYYY').format('DD MMM YYYY')
    } else {
      return moment.unix(dates[0]).format('DD MMM YYYY')
    }
  }

  return null
}

export { dateLabel }
