import React from 'react'
import { EventResult } from './style'
import { ResponsiveImage } from '../ResponsiveImage'


export default ({ title, url, content, image}) => {

  return (
    <EventResult to={url}>

    {image &&   <div className='types'>
        <ResponsiveImage src={image.url} srcSet={image.srcSet} alt={image.title} />
      </div>}

      <div className="content">
        <h4 dangerouslySetInnerHTML={{__html: title }} />
        <p dangerouslySetInnerHTML={{__html: content }}/>
      </div>
    </EventResult>
  )
}