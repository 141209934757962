import React, { Component } from 'react'

import { StaticQuery, graphql } from 'gatsby'

import { Figure } from './style'

const size_keys = [
  'thumbnail',
  'medium',
  // 'w_600',
  'large',
  'w_1200',
  'w_1920',
  'w_2400',
  'w_3000',
]

class ResponsiveImage extends Component {
  sources() {
    let ret = ''

    let srcSetUsed = {}

    if (this.props.srcSet) {
      srcSetUsed = this.props.srcSet
    } else {
      srcSetUsed = '100vw'
    }

    size_keys.forEach(size => {
      ret +=
        srcSetUsed[size] +
        ' ' +
        (srcSetUsed[size + '_width'] || srcSetUsed[size + '-width']) +
        'w, '
    })

    return ret
  }

  render() {
    return (
      <Figure ref={this.props.setRef}>
        <img
          className={this.props.className}
          sizes={this.props.sizes || '100vw'}
          alt={this.props.alt}
          srcSet={this.sources()}
          src={
            this.props.src ? this.props.src : ''
          }
        />
      </Figure>
    )
  }
}

export default ResponsiveImage;

// export default props => (
//   <StaticQuery
//     query={graphql`
//       query {
//         featureImage: allWordpressAcfOptions {
//           edges {
//             node {
//               options {
//                 ...placeholderImage
//               }
//             }
//           }
//         }
//       }
//     `}
//     render={({ featureImage }) => (
//       <ResponsiveImage {...featureImage.edges[0].node.options} {...props} />
//     )}
//   />
// )
