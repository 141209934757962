import styled, { keyframes } from 'styled-components'

import FancyLink from '../../utils/FancyLink'
const fadeInDown = keyframes`
  0% {
		opacity:0;
		transform: translateY(-20px);
  }
  100% {
		opacity:1;
		transform: translateY(0);
  }
`

const lineScale = keyframes`
  0% {
		transform: scale(0);
  }
  100% {
		transform: scale(1);
  }
`

export const HeaderContainer = styled.div`
  max-width: ${props => (props.headerImage || props.home ? '100%' : '1380px')};
  margin: 10px 10px 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.sticky ? 'flex-end' : 'space-between')};
  height: ${props => !props.headerTitle && '60px'};
  position: relative;
  z-index: 20;

  @media (min-width: 768px) {
    margin-bottom: ${props =>
      props.subcategory
        ? '30px'
        : props.user
        ? '30px'
        : props.headerImage || props.home
        ? '53px'
        : '30px'};
  }

  @media (min-width: 1024px) {
    margin-bottom: ${props =>
      props.subcategory
        ? '30px'
        : props.user
        ? '30px'
        : props.headerImage || props.home
        ? '83px'
        : '30px'};
    height: ${props => !props.headerTitle && '70px'};
  }

  @media (min-width: 1164px) {
    margin-bottom: ${props =>
      props.user && !props.headerImage ? '50px' : props.user ? '84px' : null};
  }

  @media (min-width: 1280px) {
    margin-bottom: ${props =>
      props.subcategory
        ? '10px'
        : props.generic && !props.headerImage
        ? '50px'
        : null};
    height: ${props => !props.headerTitle && '101px'};
  }

  @media (min-width: 1440px) {
    margin: ${props =>
      props.subcategory
        ? '29px 29px 9px 29px'
        : props.headerImage || props.home
        ? ' 29px 29px 90px 29px'
        : props.generic
        ? ' 29px auto 50px auto'
        : null};
  }

  @media (min-width: 1780px) {
    margin: ${props =>
      props.headerImage || props.home
        ? '45px 45px 88px 45px'
        : props.generic
        ? '45px auto 50px auto'
        : null};
  }
`

export const SearchImg = styled.img`
  margin: 0;
`

export const ImageContainer = styled.div`
  position: relative;
`

export const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  z-index: 1;
  opacity: 0.15;

  height: ${props => (props.generic ? '335px' : props.home ? '100%' : '450px')};

  @media (min-width: 1024px) {
    height: ${props =>
      props.generic ? '550px' : props.home ? '100%' : '710px'};
  }
  @media (min-width: 1780px) {
    height: ${props =>
      props.generic ? '880px' : props.home ? '100%' : '710px'};
  }
`

const scaleIn = keyframes`
  from {
    opacity: 0;
		transform: scale(1.05)
  }

  to {
    opacity: 1;
		transform: scale(1)
  }
`

const fullWidth = keyframes`
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
`

export const HeroImgWrapper = styled.div`
  position: relative;
  overflow: hidden;

  .avenueImg {
    z-index: 1;
    height: ${props =>
      props.home ? '100% !important' : props.generic ? '335px' : '450px'};

    @media (min-width: 1024px) {
      height: ${props => (props.generic ? '550px' : '710px')};
    }

    @media (min-width: 1780px) {
      height: ${props => (props.generic ? '880px' : '710px')};
    }
  }
  figure {
    img {
      z-index: -2;
      height: ${props => (props.generic ? '335px' : '450px')};
      width: 100%;
      object-fit: cover;
      margin: 0;

      @media (min-width: 1024px) {
        height: ${props => (props.generic ? '550px' : '710px')};
      }

      @media (min-width: 1780px) {
        height: ${props => (props.generic ? '880px' : '710px')};
      }
    }
  }

  img {
    z-index: -2;
    height: ${props => (props.generic ? '335px' : '450px')};
    width: 100%;
    object-fit: cover;
    margin: 0;
    display: block;

    @media (min-width: 1024px) {
      height: ${props => (props.generic ? '550px' : '710px')};
    }

    @media (min-width: 1780px) {
      height: ${props => (props.generic ? '880px' : '710px')};
    }

    &.home__img {
      height: 100%;
      opacity: 0;
      animation: ${scaleIn} ease-out 1s;
      animation-delay: 1.5s;
      animation-iteration-count: 1;
      transform-origin: 50% 50%;
      animation-fill-mode: forwards; /*when the spec is finished*/
    }
  }

  .home__p-img {
    z-index: 1;
    height: 100%;
    object-fit: cover;
    width: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
  }

  .home__bg-img {
  }
  .home__fore-img {
  }

  /*nav gradient*/
  ::before {
    content: '';
    background-image: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0.4) 1%,
      rgba(0, 0, 0, 0) 46%
    );
    z-index: 2;
    width: 100%;
    height: 50%;
    position: Absolute;
    display: ${props =>
      props.sticky
        ? 'none'
        : props.headerImage || props.home
        ? 'block'
        : 'none'};
    top: 0;
    left: 0;

    @media (min-width: 1024px) {
      height: 540px;
    }
  }

  /*headerText gradient*/

  ::after {
    content: '';
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0.2%,
      rgba(0, 0, 0, 0.3) 70%
    );
    z-index: 0;
    width: 100%;
    height: 80%;
    position: Absolute;
    display: ${props =>
      props.titleGradient || props.headerText ? 'block' : 'none'};
    bottom: 0;
    left: 0;
  }

  ${props =>
    props.home &&
    `
		background: rgb(33, 44, 31);
		height: 450px;
	`} @media (min-width: 1024px) {
    ${props =>
      props.home &&
      `
			height: 710px;
		`};
  }

  @media (min-width: 1120px) {
    ${props =>
      props.home &&
      `
			background: rgb(33, 44, 31);
			height: calc(100vh - 10px - 10px);
		`};
  }

  @media (min-width: 1440px) {
    ${props =>
      props.home &&
      `
			background: rgb(33, 44, 31);
			height: calc(100vh - 29px - 29px);
		`};
  }

  @media (min-width: 1780px) {
    ${props =>
      props.home &&
      `
			height: calc(100vh - 45px - 45px);
		`};
  }
`

export const CategoryLabel = styled.div`
  margin-left: 1px;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    margin-left: 2px;
  }

  @media (min-width: 1024px) {
    margin-left: 3px;
  }

  @media (min-width: 1780px) {
    margin-left: 3px;
  }

  svg {
    margin: 0 11px;
    fill: ${props => (props.headerImage || props.home ? '#FFF' : '#E0D6C8')};
  }

  a:first-of-type {
    svg {
      path {
        fill: ${props =>
          props.headerImage || props.home ? '#FFF' : '#8B3A19'};
      }
    }

    :hover {
      svg {
        path {
          fill: ${props =>
            props.headerImage || props.home ? '#a55331' : null};
        }
      }
    }
  }

  a {
    line-height: 1;
    text-transform: capitalize;

    :hover {
      span {
        color: #a55331;
      }
    }
  }

  a.text {
    pointer-events: ${props => !props.breadcrumb.slug && 'none'};
  }

  span {
    font-size: 12px;
    color: ${props =>
      props.headerImage || props.home ? '#FFFFFF' : '#212C1F'};
    font-weight: 400;
  }
`

export const TitleWrapper = styled.div`

	z-index: 3;
	position: ${props => (props.headerImage || props.home ? 'absolute' : null)};
	left: 0;
	bottom: 0;
	padding-left: ${props => (props.headerImage || props.home ? '22px' : '0')};
	width: 100%;
	padding-top: ${props => props.generic && !props.headerImage && '85px'};


	@media(min-width:480px) {
	padding-left: ${props => (props.headerImage || props.home ? '26px' : '0')};

	}
	/* @media (min-width: 768px) {
		padding-left: ${props =>
      props.home ? '90px' : props.headerImage || props.home ? '70px' : null};

	} */

	@media (min-width: 768px) {
    padding: ${props => (props.headerImage || props.home ? null : '0 20px')};
		padding-top: ${props => props.generic && !props.headerImage && '115px'};

  }

	@media (min-width: 1024px) {
    padding-left: ${props =>
      props.home ? '90px' : props.headerImage || props.home ? '90px' : null};


		margin:0 auto;
	}

	@media (min-width: 1280px) {
    padding: ${props => (props.headerImage || props.home ? null : '0 110px')};
		padding-top: ${props => props.generic && !props.headerImage && '160px'};

	}

	@media (min-width: 1440px) {
    padding: ${props => (props.headerImage || props.home ? null : '0 90px')};
		padding-top: ${props => props.generic && !props.headerImage && '180px'};

	}



	@media (min-width: 1615px) {
		max-width: 1380px;
		left: 0;
		right: 0;
		margin: 0 auto;
		padding-left: ${props => (props.headerImage || props.home ? '0' : null)};

		/* margin-top: ${props =>
      props.headerImage || props.home ? '0px' : '100px'}; */
	}


`

export const HeaderText = styled.div`
  margin-top: 20px;
  padding-left: 8px;

  margin-bottom: 0;
  z-index: 2;

  @media (min-width: 768px) {
    padding-left: 20px;
    margin-top: 40px;
  }

  @media (min-width: 1024px) {
  }
  @media (min-width: 1280px) {
    max-width: 1380px;
    position: Absolute;
    margin: 0 auto;
    bottom: 85px;
    padding-left: 75px;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
  }

  @media (min-width: 1615px) {
    padding-left: 0;
  }

  @media (min-width: 1780px) {
    bottom: 100px;
    /* max-width: 1500px; */
  }

  p {
    font-family: Freight Big;
    font-size: 24px;
    color: #212c1f;
    letter-spacing: 0;
    margin: 0;
    line-height: 133%;
    padding-right: 20px;

    @media (min-width: 768px) {
      max-width: 88%;
    }

    @media (min-width: 1280px) {
      color: #ffffff;
      max-width: 50%;
      padding-right: 90px;
    }

    @media (min-width: 1615px) {
      max-width: 50%;
      padding-right: 0px;
    }

    @media (min-width: 1780px) {
      bottom: 100px;
      /* max-width: 1500px; */
    }
  }
`

export const Title = styled.h1`
	font-size: 31px;
	color: ${props => (props.headerImage || props.home ? '#fbf9f6' : '212C1F')};
	letter-spacing: -0.25px;
	line-height: 100%;
	text-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
	margin: 0;
	width: 100%;
	max-width: ${props => (props.home ? '70%' : '100%')};
	padding-bottom: ${props =>
    props.hasLink ? '66px' : props.headerImage || props.home ? '19px' : '25px'};
	border-bottom: ${props =>
    props.headerImage || props.home ? 'none' : '1px solid black'};
	margin-top: 12px;
	max-width:${props => (props.home ? '95%' : props.headerImage ? '80%' : '100%')};
	position:relative;
	@media(min-width:480px) {
	font-size: 36px;
	max-width:${props => (props.home ? '91%' : props.headerImage ? '80%' : '100%')};
	padding-bottom: ${props =>
    props.hasLink ? '66px' : props.headerImage || props.home ? '26px' : '25px'};

	}


	.anim-1 {
		position: relative;
		color: transparent;

		&:before {
			content: attr(data-letters);
			position: absolute;
			z-index: 2;
			overflow: hidden;
			color: #fff;
			white-space: nowrap;
			width: 0%;
      animation: ${fullWidth} 0.8s ease-in-out;
		  animation-delay: 1s;
		  animation-fill-mode:forwards;



		}
	}

	.anim-2 {
		position: relative;
		color: transparent;

		&:before {
			content: attr(data-letters);
			position: absolute;
			z-index: 2;
			overflow: hidden;
			color: #fff;
			white-space: nowrap;
			width: 0%;
  		animation: ${fullWidth} 0.8s ease-in-out;
			animation-delay: 1.3s;
			animation-fill-mode:forwards;



		}
	}

@media(min-width: 768px) {
	font-size: 45px;
	/* padding-bottom: ${props =>
    props.home ? '50px' : props.headerImage || props.home ? '70px' : null}; */

}
	@media (min-width: 1024px) {
		margin-top: 20px;
		padding-bottom: ${props =>
      props.home ? '143px' : props.headerImage || props.home ? '80px' : '29px'};
		font-size: 65px;
		max-width:${props => (props.home ? '60%' : null)};

	}

	@media (min-width: 1280px) {
		font-size: 72px;
	margin-top: 20px;
	max-width: ${props =>
    props.home
      ? '60%'
      : props.headerText && props.headerImage
      ? '43%'
      : props.headerImage
      ? '80%'
      : '100%'};

	}

		@media (min-width: 1615px) {

	max-width: ${props =>
    props.home
      ? '60%'
      : props.headerText && props.headerImage
      ? '45%'
      : '100%'};

	}

	@media (min-width: 1780px) {
		font-size: 80px;
		padding-bottom: ${props =>
      props.home ? '143px' : props.headerImage || props.home ? '100px' : null};
		margin-top: 34px;
	}


`

// create a contant with the animation in the cliboard, it will be spread on h5 or link
export const Animation = styled.div``

export const GetStarted = styled.div`
  position: absolute;
  z-index: 10;
  display: ${props => (props.showMobile ? 'block' : 'none')};
  bottom: 32px;
  left: 26px;

  @media (min-width: 1024px) {
    display: block;
    bottom: 95px;
    left: 93px;
  }

  @media (min-width: 1620px) {
    max-width: 1380px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  h5 {
    font-weight: 400;
    margin: 0;
    font-size: 14px;
    color: #f6efe9;
    text-transform: capitalize;
    animation: ${fadeInDown} 460ms ease-in-out;
    animation-fill-mode: both;
    animation-delay: 2.4s;

    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }

  a {
    h2 {
      font-weight: 400;
      margin: 0;
      font-size: 14px;
      color: #f6efe9;
      text-transform: capitalize;

      @media (min-width: 1024px) {
        font-size: 16px;
      }
    }
    animation: ${fadeInDown} 460ms ease-in-out;
    animation-fill-mode: both;
    animation-delay: 2.4s;
  }

  ::after {
    content: '';
    position: absolute;
    bottom: -32px;
    height: 20px;
    opacity: 0.5;
    width: 1px;
    left: 8px;
    background-color: #f6efe9;
    transform-origin: bottom;
    animation: ${lineScale} 460ms ease-in-out;
    animation-fill-mode: both;
    animation-delay: 2.4s;

    @media (min-width: 1024px) {
      bottom: -96px;
      height: 60px;
    }
  }
`

export const BallaratLogo = styled.a`
  position: ${props =>
    props.generic || props.sticky ? 'relative' : 'absolute'};
  display: ${props => (props.generic || props.sticky ? 'block' : 'block')};
  top: ${props => (props.home ? '10px' : null)};
  left: ${props => (props.home ? '10px' : null)};
  justify-self: ${props =>
    props.generic ? 'start' : props.sticky ? 'start' : null};
  z-index: 3;

  img {
    margin: 0;
    display: block;
    width: ${props =>
      props.sticky ? '48px' : props.generic ? '120px' : '115px'};
    @media (min-width: 1024px) {
      width: ${props =>
        props.sticky ? '48px' : props.generic ? '201px' : '201px'};
    }

    @media (min-width: 1280px) {
      width: ${props => (props.generic ? '80px' : '201px')};
    }
  }

  @media (min-width: 1024px) {
    justify-self: ${props => (props.sticky ? 'center' : null)};
    display: ${props => (props.generic || props.sticky ? 'block' : 'null')};
    top: auto;
    bottom: ${props =>
      props.generic || props.sticky ? 'auto' : props.home ? '460px' : null};
    left: ${props => (props.home ? '30px' : null)};
  }

  @media (min-width: 1024px) {
    justify-self: ${props => (props.sticky ? 'center' : null)};
    display: ${props => (props.generic || props.sticky ? 'block' : 'null')};
    bottom: ${props =>
      props.generic || props.sticky ? 'auto' : props.home ? '103px' : null};
    left: auto;
    right: ${props =>
      props.generic || props.sticky ? 'auto' : props.home ? '91px' : null};
  }
`

export const StickyTrigger = styled.div`
  position: absolute;
  top: 1700px;
  width: 1px;
  height: 1px;
  background: transparent;
  pointer-events: none;
  z-index: 100;
`

export const IconWrapper = styled.div`
  position: relative;
  width: 16px;
  height: 18px;
`
export const LinkWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 16px;
  align-items: center;
`

export const LinkIcon = styled(FancyLink)`
  display: block;
  width: fit-content;
  cursor: pointer;
  &:hover {
    h2 {
      text-decoration: underline;
    }
  }
`
