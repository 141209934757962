import React from 'react'
// import { Link } from 'gatsby';
import FancyLink from '../../utils/FancyLink.js'

import { dateLabel } from '../../utils/dateLabel'
import { ReactComponent as Calendar } from '../../images/calendar.svg'
import { getCleanCategories } from '../../utils/helpers'

import { getImageRatio } from '../../utils/helpers'
import { BrownButton } from '../BrownButton/index.js'

import {
  TeaserContainer,
  TeaserWrapper,
  ResponsiveImgMain,
  ImgMain,
  TeaserInfo,
  ImgColumn,
  ImgWrap,
  InnerInfo,
  IconsContainer,
  CircleIcon,
  Featured,
  InfoWrapper,
  ImageOverlay,
  FavouriteWrapper,
  TitleRow,
  DateRow,
  RegionDateRow,
} from './style.js'

import { Icons } from '../Icons'
import FavouriteButton from '../FavouriteButton/FavouriteButton.js'
import RegionTag from './RegionTag.js'

const Teaser = props => {
  const { openInNewTab } = props || {}
  var striptags = require('striptags')
  const categoryArray = getCleanCategories(props.categories)
  const region = props.region

  const shortDescription =
    props.desc && props.desc.length > 100
      ? props.desc.substring(0, 200) + '...'
      : props.desc

  return (
    <TeaserContainer
      gridLarge={props.gridLarge}
      gridLong={props.gridLong}
      homeStory={props.homeStory}
      festivalHighlight={props.festivalHighlight}
    >
      <TeaserWrapper
        {...props}
        className={props.lastTeaser && 'last-teaser'}
        storyHp={true}
      >
        <ImgColumn {...props}>
          <ImgWrap {...props}>
            <ImageOverlay className="teaser-overlay" {...props} />
            <IconsContainer className="icons-container" {...props}>
              <Icons
                event={
                  props.search &&
                  props.categories &&
                  props.categories[0] === 'Events'
                }
                {...props}
              />
            </IconsContainer>

            {(props.storyMain || props.itinerary || props.categoryLanding) && (
              <InnerInfo {...props}>
                <div>
                  {props.days && <Calendar />}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: props.itinerary
                        ? `${props.days} day${props.days > 1 ? 's' : ''}`
                        : props.categories
                        ? categoryArray[0]
                        : null,
                    }}
                  />

                  {props.storyMain && props.date && (
                    <span>{dateLabel(props.date)}</span>
                  )}
                </div>
                <h3>
                  <FancyLink
                    openInNewTab={openInNewTab}
                    to={
                      props.url ? props.url.replace(/^.*\/\/[^\/]+/, '') : '/'
                    }
                    dangerouslySetInnerHTML={{
                      __html: striptags(
                        props.descHeading ? props.descHeading : props.title
                      ),
                    }}
                  />
                </h3>
              </InnerInfo>
            )}

            {props.img?.sourceUrl ? (
              //fix sizes
              <ResponsiveImgMain
                className="teaser_img"
                fluid={props.img}
                alt=""
              />
            ) : props.img?.url ? (
              <ImgMain
                className="normal-image-wrapper"
                src={props.img.url}
                alt="teaserimg"
              />
            ) : (
              <ImgMain
                src="https://res.cloudinary.com/avenue/image/upload/v1637111400/vb-placeholder_kwrhkq.png"
                alt="teaserimg"
              />
            )}

            {props.free && (
              <CircleIcon {...props}>
                <h3>FREE</h3>
              </CircleIcon>
            )}

            {props.soldOut && (
              <CircleIcon {...props}>
                <h3>SOLD OUT</h3>
              </CircleIcon>
            )}
          </ImgWrap>
        </ImgColumn>

        {(props.fullWidth ||
          props.festivalStay ||
          props.search ||
          props.standard ||
          props.story ||
          props.storyMain ||
          props.header) && (
          <InfoWrapper {...props}>
            {props.featured && (
              <Featured>
                {props.featuredTitle ? props.featuredTitle : 'Featured'}
              </Featured>
            )}

            <TeaserInfo {...props}>
              <DateRow>
                <RegionDateRow showLine={props?.date?.length}>
                  {region &&
                    (!props.subcategory && !props.search && !props.story) && (
                      <RegionTag {...region} />
                    )}
                  {(props.categories || props.date) && (
                    <div>
                      {(props.story ||
                        props.search ||
                        props.storyFullWidth) && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: categoryArray[0]
                              ? categoryArray[0]
                              : 'General',
                          }}
                        />
                      )}

                      {(props.story || (props.search && props.event)) &&
                        props.date && (
                          <span>
                            {props.search && ': '}
                            {dateLabel(props.date)}
                          </span>
                        )}

                      {(props.fullWidth || props.standard) && props.event && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: props.displayCat
                              ? categoryArray[0]
                              : props.date
                              ? dateLabel(props.date)
                              : null,
                          }}
                        />
                      )}
                    </div>
                  )}
                </RegionDateRow>

                {props.standard && props.date && props.databaseId && (
                  <FavouriteWrapper>
                    <FavouriteButton id={props.databaseId} />
                  </FavouriteWrapper>
                )}
              </DateRow>

              <TitleRow>
                <h3>
                  <FancyLink
                    openInNewTab={openInNewTab}
                    to={
                      props.website
                        ? props.website
                        : props.url
                        ? props.url.replace(/^.*\/\/[^\/]+/, '')
                        : '/'
                    }
                    dangerouslySetInnerHTML={{ __html: props.title }}
                  />
                </h3>

                {props.standard && !props.date && props.databaseId && (
                  <FavouriteWrapper>
                    <FavouriteButton id={props.databaseId} />
                  </FavouriteWrapper>
                )}
              </TitleRow>
              {props.festivalHighlight && (
                <RegionTag {...region} className="festivalHighlight" />
              )}

              <p>
                <FancyLink
                  openInNewTab={openInNewTab}
                  to={
                    props.website
                      ? props.website
                      : props.url
                      ? props.url.replace(/^.*\/\/[^\/]+/, '')
                      : '/'
                  }
                  dangerouslySetInnerHTML={{ __html: shortDescription }}
                />
              </p>

              {props.event &&
                (props.featured || props.fullWidth) &&
                !props.hideFavourites &&
                props.databaseId && (
                  <div style={{ marginTop: 30 }}>
                    <FavouriteButton id={props.databaseId} big />
                  </div>
                )}

              {props.passAttraction && (
                <div>
                  {props.address && <span>{props.address}</span>}
                  {props.phone && <span>{props.phone}</span>}
                </div>
              )}

              {props.passAttraction && (
                <p className={'attraction-cta'}>Read more</p>
              )}

              {region &&
                (props.subcategory || props.search || props.story) &&
                !props.festivalHighlight && <RegionTag {...region} />}

              {(props.storyFullWidth || props.festivalStay) && (
                <div>
                  {props.categories && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: props.categories[0],
                      }}
                    />
                  )}

                  {props.address && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: props.address,
                      }}
                    />
                  )}

                  {props.date && <span>{dateLabel(props.date)}</span>}
                  {props.website && (
                    <span className="hover-brown">More information</span>
                  )}
                </div>
              )}
            </TeaserInfo>
            {props.bookingLink && (
              <BrownButton festivals link={props.url} text="Discover more" />
            )}
          </InfoWrapper>
        )}
      </TeaserWrapper>
    </TeaserContainer>
  )
}

export default Teaser
