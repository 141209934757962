import React from 'react';
import { ButtonWrapper, Button } from './style.js';
import { ReactComponent as Arrow } from '../../images/arrow-right.svg';
import FancyLink from '../../utils/FancyLink.js';

const BrownButton = (props) => {
	return (
		<ButtonWrapper className="brownBtn" festivals={props.festivals}>
			<Button>
				<Arrow />
			</Button>

			<FancyLink to={props.link}>
				<span>{props.text} </span>
				{props.price && <span className="price">(from {props.price})</span>}
			</FancyLink>
		</ButtonWrapper>
	);
};

export default BrownButton;
