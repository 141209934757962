import React from 'react'
import cityBallaratLogo from '../../images/city-logo.png'
import tmwvLogo from '../../images/tmwv-logo.png'
import visitBallaratLogo from '../../images/vb-solid-stack.svg'

import flags from '../../images/flags.png'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import { SocialButtons } from '../NavBar'

import {
  Container,
  NavWrapper,
  InfoContainer,
  FooterContainer,
  CopyRight,
  LogosWrapper,
  LogosButtonWrapper,
  AcknoledgementWrapper,
} from './styles'
import FancyLink from '../../utils/FancyLink'

export default function Index() {
  const today = new Date()
  const currentYear = today.getFullYear()

  const data = useStaticQuery(graphql`
    {
      primary: wpMenu(slug: { eq: "footer-primary" }) {
        name
        menuItems {
          nodes {
            label
            connectedNode {
              node {
                ... on WpPage {
                  status
                }
              }
            }
            path
          }
        }
      }
      secondary: wpMenu(slug: { eq: "footer-secondary" }) {
        name
        menuItems {
          nodes {
            label
            connectedNode {
              node {
                ... on WpPage {
                  status
                }
              }
            }
            path
          }
        }
      }
    }
  `)

  const primaryLinks = data.primary.menuItems.nodes
  const secondaryLinks = data.secondary.menuItems.nodes

  return (
    <Container>
      <NavWrapper>
        {primaryLinks.map((link, index) => (
          <li key={index}>
            {link.connectedNode ? (
              <Link to={link.path}>{link.label}</Link>
            ) : (
              <a target="_blank" href={link.path} rel="noopener noreferrer">
                {link.label}
              </a>
            )}
          </li>
        ))}
      </NavWrapper>
      <InfoContainer>
        <SocialButtons footer />

        <p className="address__heading">Information Centre</p>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.google.com.au/maps/place/${encodeURIComponent(
            '225 Sturt Street Ballaract VIC 3350'
          )}`}
        >
          <p className="address__location">Town Hall</p>
          <p className="address__full">
            225 Sturt Street
            <br />
            Ballarat VIC 3350
          </p>
        </a>

        <p className="address__location">
          <a as="a" href={`tel:${'1800 44 66 33'.replace(/\s/g, '')}`}>
            1800 44 66 33
          </a>{' '}
          <span className="hide-print">
            |{' '}
            <a
              rel="noopener noreferrer"
              href="mailto:hello@visitballarat.com.au"
            >
              Email us
            </a>
          </span>
        </p>
      </InfoContainer>
      <FooterContainer>
        <LogosButtonWrapper>
          <LogosWrapper>
            <div>
              <img src={tmwvLogo} alt="logo" />
            </div>
          </LogosWrapper>
          <FancyLink to="https://tourismmidwestvic.com.au/">
            Industry Hub
          </FancyLink>
        </LogosButtonWrapper>
        <AcknoledgementWrapper>
          <p>
            Across Victoria’s Midwest, we acknowledge that we travel across the
            ancient landscapes of many First Peoples communities.
          </p>
          <p>
            These lands have been nurtured and cared for over tens of thousands
            of years and we respect the work of Traditional Custodians for their
            ongoing care and protection.
          </p>
          <p>
            We recognise the past injustices against Aboriginal and Torres
            Strait Islander peoples in this country. As our knowledge grows, we
            hope that we can learn from their resilience and creativity that has
            guided them for over 60,000 years.
          </p>
          <p>
            As we invite people to visit and explore Victoria’s Midwest, we ask
            that alongside us, you also grow to respect the stories, living
            culture and connection to Country of the Ancestors and Elders of our
            First Peoples.
          </p>
        </AcknoledgementWrapper>

        <CopyRight as="div">© {currentYear} Visit Ballarat</CopyRight>
      </FooterContainer>
    </Container>
  )
}
