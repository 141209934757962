import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
const AvenueImg = (props) => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query imgPlaceHolder {
        placeholderImage: file(relativePath: { eq: "vb-placeholder.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  // console.log("props:", props)

  //placeholder fluid
  const placeholderImg = placeholderImage.childImageSharp.fluid

  //internal query fluid
  const queryImg =
    props?.fluid?.childImageSharp?.fluid ||
    props?.fluid?.node?.childImageSharp?.fluid ||
    props?.fluid

  //wp fluid
  const wpMedia = props?.fluid?.mediaDetails
  const wpAspectRatio = wpMedia ? wpMedia.width / wpMedia.height : null
  const wpImg = props?.fluid?.sourceUrl &&
    props?.fluid?.srcSet && {
      aspectRatio: props.fluid.aspectRatio || wpAspectRatio || 1,
      sizes: props.fluid.sizes || "100vw",
      src: props.fluid.sourceUrl,
      srcSet: props.fluid.srcSet,
    }

  // return img if GIF or no srcSet
  if (
    props?.fluid?.sourceUrl &&
    (props.fluid.sourceUrl.includes(".gif") || !props.fluid.srcSet)
  ) {
    return (
      <div className={`${props.className} avenueImg__imgWrapper`}>
        <img
          src={props.fluid.sourceUrl}
          alt={props.alt}
          loading="lazy"
          style={props.style}
        />
      </div>
    )
  } else {
    //otherwise return gatsby image
    return (
      <Img
        ref={props.setRef}
        className={`${props.className} avenueImg`}
        loading={props.loading || "lazy"}
        fadeIn={props.noFade ? false : true}
        fluid={wpImg || queryImg || placeholderImg}
        alt={props.alt}
        style={props.style}
      />
    )
  }
}

export default AvenueImg