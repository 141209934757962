import React, { Component } from 'react'

import NavHover from './navHover'
import { Link } from 'gatsby'
import arrowRight from '../../images/arrow-right.svg'

import { HoverPosition, ListItem } from './style.js'

class NavBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hovered: false,
      menuOpen: false,
      loadTeasers: false,
    }

    this.navHover = this.navHover.bind(this)
    this.navUnhover = this.navUnhover.bind(this)
  }

  componentDidMount() {
    // console.log(this.props.path.replace(/^.*\/\/[^\/]+/, ''))
    this.mediaQuery = window.matchMedia('(min-width: 1024px)')
  }

  navHover(event) {
    event.persist()

    if (this.mediaQuery.matches) {
      this.setState(
        {
          hovered: true,
          loadTeasers: true,
        },
        // () => console.log('hovered')
        () => this.props.closeSearchMenu(event)
      )
    }
  }

  navUnhover() {
    this.setState(
      {
        hovered: false,
        loadTeasers: false,
      }
      // () => console.log('unhovered')
    )
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.sticky !== nextProps.sticky) {
      return true
    }
    if (this.state.hovered !== nextState.hovered) {
      return true
    }

    return false
  }

  render() {
    return (
      <ListItem
        className={this.props.className}
        onMouseLeave={this.navUnhover}
        onMouseEnter={this.navHover}
        sticky={this.props.sticky}
        hovered={this.state.hovered}
        megaMenu={this.props.megaMenu}
        home={this.props.home}
        back={this.props.back}
        mobHeader={this.props.mobHeader}
      >
        <Link
          to={
            this.props.path ? this.props.path.replace(/^.*\/\/[^\/]+/, '') : '/'
          }
        >
          <span>{this.props.label}</span>
        </Link>
        <HoverPosition
          finalUnhover={this.props.finalUnhover}
          sticky={this.props.sticky}
          hovered={this.state.hovered}
          {...this.props}
          ref={element => {
            this.dropdownMenu = element
          }}
        >
          {this.props.megaMenu && (
            <NavHover
              hovered={this.state.hovered}
              featuredArticles={this.props.featuredArticles}
              heading={this.props.heading}
              description={this.props.description}
              path={this.props.path}
              megaMenu={this.props.megaMenu}
            />
          )}
        </HoverPosition>
        <img src={this.props.back === true ? arrowRight : arrowRight} alt="" />{' '}
      </ListItem>
    )
  }
}

export default NavBar
