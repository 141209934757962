import { useEffect } from 'react'

import { travis, saori, jones, defaultHome } from './theme.js'

export function getCleanCategories(categories) {
  let categoryArray = []

  if (categories) {
    categoryArray = categories.map(category => {
      if (category.name) {
        return category.name
      } else return category
    })
  }

  return categoryArray
}

export function getImageRatio(srcSet) {
  let ratio

  if (!srcSet) {
    return 16 / 9
  }

  if (srcSet.w_3000_width) {
    ratio = srcSet.w_3000_width / srcSet.w_3000_height
  } else {
    ratio = srcSet['w_3000-width'] / srcSet['w_3000-height']
  }

  return ratio
}

export function checkSite(site) {
  const prefix1 = 'http://'

  if (
    site &&
    site.substr(0, prefix1.length) !== prefix1 &&
    (site.includes('www.') || site.includes('.com')) &&
    !site.includes('https')
  ) {
    return prefix1 + site
  } else {
    return site
  }
}

export function textTruncate(str, length, ending) {
  if (length == null) {
    length = 100
  }

  if (ending == null) {
    ending = '...'
  }

  //remove https
  let processedStr = str.replace(/(^\w+:|^)\/\//, '')

  //remove  forward slash at end if exists
  if (str.substr(str.length - 1) === '/') {
    processedStr = processedStr.substring(0, processedStr.length - 1)
  }

  if (processedStr.length > length) {
    return processedStr.substring(0, length - ending.length) + ending
  } else {
    return processedStr
  }
}

export function getTheme(themeString) {
  let theme

  switch (themeString) {
    case 'travis':
      theme = travis
      break
    case 'saori':
      theme = saori
      break
    case 'jones':
      theme = jones
      break
    default:
      theme = defaultHome
  }

  return theme
}

export const useEscHandler = doSomething => {
  function escEvent(event) {
    if (event.keyCode === 27) {
      doSomething()
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('keydown', escEvent)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', escEvent)
    }
  })
}
