import styled from 'styled-components'

export const Container = styled.div`
  display: block;
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 0;
  left: 0;
  z-index: 1000;

  background: teal;
  width: 100%;
  height: 65px;
  padding-bottom: 5px;
  background: #fbf9f6;
  box-shadow: 0 -15px 35px 0 rgba(0, 0, 0, 0.25);
  @media (min-width: 768px) {
    display: none;
  }

  @media print {
    display: none;
  }
`

export const ItemWrapper = styled.div`
  a {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      margin-bottom: 5px;
      width: 25px;
      height: 25px;
    }
  }

  span {
    font-weight: 500;
    font-size: 8px;
  }
`
