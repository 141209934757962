import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  margin-top: ${props => props.festivals && 'auto'};
  display: inline-flex;
  /* width: 157px; */
  align-items: center;
  cursor: pointer;
  span {
    font-size: 18px !important;
    font-weight: 500;
    margin: 0 !important;

    color: #212c1f;

    &.price {
      font-size: 14px !important;
      display: inline-block;
    }
  }

  :hover {
    span {
      color: #a55331;
    }
  }

  a {
    :before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    span {
      margin: 0;
    }
  }
`

export const Button = styled.div`
  width: 40px;
  height: 40px;
  background: #a55331;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  svg {
    width: 10px;
    path {
      fill: white;
    }
  }
`
