import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
} from 'react'

const FavouritesContext = createContext()

function FavouritesProvider(props) {
  const [favourites, setFavourites] = useState(null)
  const [showToast, setShowToast] = useState(false)

  const addFavourite = id => {
    const newFavourites = [id, ...favourites]
    setFavourites(newFavourites)
    window.localStorage.setItem('favourites', JSON.stringify(newFavourites))
    setShowToast("Added to your must-do's")
  }

  const removeFavourite = id => {
    setFavourites(prevFavourites => {
      const filteredFavourites = prevFavourites?.filter(entry => entry !== id)
      return filteredFavourites
    })
  }

  const removeAllFavourites = () =>
    favourites.forEach(favourite => removeFavourite(favourite))

  useEffect(() => {
    if (favourites) {
      window.localStorage.setItem('favourites', JSON.stringify(favourites))
    }
  }, [favourites])

  const value = useMemo(
    () => [
      favourites,
      setFavourites,
      showToast,
      setShowToast,
      addFavourite,
      removeFavourite,
      removeAllFavourites,
    ],
    [favourites, showToast]
  )

  function isValidJSON(value) {
    try {
      JSON.parse(value)
      return true
    } catch (error) {
      return false
    }
  }

  useEffect(() => {
    const storedFavourites = localStorage?.getItem('favourites')

    if (storedFavourites && isValidJSON(storedFavourites)) {
      setFavourites(JSON.parse(storedFavourites))
    } else {
      setFavourites([])
    }
  }, [])

  return <FavouritesContext.Provider value={value} {...props} />
}

function useFavourites() {
  const context = useContext(FavouritesContext)
  if (!context) {
    throw new Error(`useFavourites must be used within a FavouritesProvider`)
  }
  const [
    favourites,
    setFavourites,
    showToast,
    setShowToast,
    addFavourite,
    removeFavourite,
    removeAllFavourites,
  ] = context
  return {
    favourites,
    setFavourites,
    showToast,
    setShowToast,
    addFavourite,
    removeFavourite,
    removeAllFavourites,
  }
}

export { FavouritesProvider, useFavourites }

function isJson(item) {
  item = typeof item !== 'string' ? JSON.stringify(item) : item

  try {
    item = JSON.parse(item)
  } catch (e) {
    return false
  }

  if (typeof item === 'object' && item !== null) {
    return item
  }

  return false
}
