import React, { useState } from 'react'
import styled from 'styled-components'
import { device } from '../../utils/breakpoints'

import { ReactComponent as Favourite } from '../../images/favorite.svg'
import { ReactComponent as AddFavourite } from '../../images/add-favorite.svg'
import { ReactComponent as RemoveFavourite } from '../../images/remove-favorite.svg'
import { ReactComponent as AddFavouriteBig } from '../../images/add-favorite-big.svg'
import { useFavourites } from '../../utils/useFavourites'

const FavouriteButton = ({ id, big, showText, listing }) => {
  const { addFavourite, removeFavourite, favourites } = useFavourites()

  const isFavourited = favourites?.includes(id)
  return (
    <Container
      onClick={() => {
        isFavourited ? removeFavourite(id) : addFavourite(id)
      }}
      big={big}
      showText={showText}
    >
      {isFavourited ? (
        listing ? (
          <RemoveFavourite />
        ) : (
          <Favourite />
        )
      ) : big ? (
        <AddFavouriteBig />
      ) : (
        <AddFavourite />
      )}

      {(big || showText) && (
        <span>
          {isFavourited
            ? listing
              ? "Remove from must-do's"
              : "Added to must-do's"
            : "Add to must-do's"}
        </span>
      )}
    </Container>
  )
}

export default FavouriteButton

const Container = styled.div`
  z-index: 15;
  position: relative;
  display: flex;
  /* align-items: center; */

  svg {
    height: 14px;
    width: 16px;

    ${props =>
      props.big &&
      `
      height:20px;
      width:22px;
    `};
  }

  cursor: pointer;
  &:before {
    content: '';
    content: ${props => (props.big || props.showText) && 'none'};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 160%;
    height: 160%;
  }

  span {
    font-size: 16px !important;
    font-weight: 500;
    font-size: ${props => props.big && '18px !important'};
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    margin-left: 14px;
    margin-left: ${props => props.big && '15px'};
    line-height: 100% !important;
    color: #212c1f !important;
    transition: 100ms;
  }

  :hover {
    span {
      color: #a55331 !important;
    }
  }

  @media print {
    display: none;
  }
`
