import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1201px;
  margin: 0 auto;
  width: calc(100% - 20px);

  @media (min-width: 375px) {
    margin-top: 40px;
  }
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1.7fr 1fr;
    grid-template-rows: 1;
    border-top: 1px solid #212c1f;
    border-bottom: 1px solid #212c1f;
    padding-top: 20px;
    width: calc(100% - 60px);

    padding-bottom: 50px;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 2.8fr 1fr;
    margin-top: 70px;
  }
`

export const SignUpContainer = styled.div`
	border-top:1px solid #212c1f;

	@media (min-width: 768px) {
		border-top: none;
		padding-top: 5px;
	}
	@media (min-width: 1024px) {
		border-top: none;
		padding-left: 0;
	}
`;
export const Header = styled.div`
	width: 100%;
	padding-top:45px;

	h4 {
		font-weight: 500;
		font-size: 14px;
		color: #545c52;
		letter-spacing: 0;
		text-align: center;
		text-transform: lowercase;
		::first-letter {
			text-transform: capitalize;
		}

		@media (min-width: 768px) {
			text-align: left;
			margin-bottom: 5px;
		}

		@media (min-width: 1024px) {
			grid-column: 1;
			font-size: 16px;
			letter-spacing: 0;
			text-align: left;
		}
	}

	h1 {
		font-family: Freight Big;
		font-weight: 300;
		font-size: 28px;
		color: #212c1f;
		letter-spacing: -0.22px;
		text-align: center;
		line-height: 42px;
		text-transform: lowercase;
		::first-letter {
			text-transform: capitalize;
		}

		@media (min-width: 768px) {
			font-size: 36px;
			letter-spacing: -0.28px;
			line-height: 42px;
			text-align: left;
		}

    @media (min-width: 1024px) {
      grid-column: 1;
      font-weight: 700;
      text-align: left;
    }

    @media (min-width: 1280px) {
      font-size: 40px;
      letter-spacing: -0.31px;
      line-height: 42px;
    }
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    grid-template-rows: 1;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const Form = styled.form`
  width: 100%;
  font-size: 16px;
  color: #212c1f;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  margin-bottom: 10px;

	@media (min-width: 768px) {
		margin-bottom: 0;
	}
	@media (min-width: 1024px) {
		font-weight: 300px;
		font-size: 20px;
		color: #545c52;
		letter-spacing: 0;
		line-height: 30px;
	}

  input {
    display: ${props => (props.disabled ? 'none' : 'inline')};
    width: 80%;
    margin-right: 6px;
    line-height: 30px;
  
    background: transparent;
    outline: none;
    border: none;
    border-radius: 0;
    border-bottom: ${props =>
      props.showError ? '1px solid #A7444D' : '1px solid #212c1f'};
    padding-bottom: 5px;

    @media (min-width: 375px) {
      width: 83%;
    }

    @media (min-width: 768px) {
      width: 75%;
      margin-right: 20px;
    }
    @media (min-width: 1024px) {
      width: 65%;
      margin-right: 20px;
      margin-left: 10px;
      padding-bottom: 7px;
    }

    @media (min-width: 1366px) {
      width: 75%;
      margin-left: 0;
      padding-bottom: 8px;
    }
  }
`

export const Sent = styled.h5`
  display: ${props => (props.disabled ? 'block' : 'none')};
  font-weight: 500;
  font-size: 16px;
  color: #545c52;
  letter-spacing: 0;
  margin-bottom: 0;
  line-height: 24px;
  text-transform: lowercase;
  margin-top: 10px;

  span,
  ::first-letter {
    text-transform: uppercase;
  }
`

export const Button = styled.button`
  display: ${props => (props.disabled ? 'none' : 'inline-flex')};
  font-size: 24px;
  font-weight: 600;
  width: 40px;
  height: 41px;
  outline: none;
  text-align: center;
  background: transparent;
  border: 1px solid #212c1f;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  svg {
    width: 10px;
    margin: 0;
  }

  :hover {
    border: 1px solid #a55331;

    svg {
      path {
        fill: #a55331;
      }
    }
  }

  @media (min-width: 375px) {
    width: 48px;
    height: 49px;
    padding: 0;
  }

	@media (min-width: 768px) {
		padding: 0;
	}
`;

export const SocialContainter = styled.div`
	width: calc(100% - 20px);
	padding-top: 15px;
	padding-left: 18px;
	display: flex;
	justify-content: center;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-left: 1px solid #b9bab4;
    margin-bottom: 0;
    padding-left: 37px;
    padding-top: 0;
    margin-top: 44px;
  }
  @media (min-width: 1024px) {
    padding-left: 52px;
    justify-content: center;
  }
`

export const ErrorMessage = styled.p`
  font-weight: 400;
  color: ${props => props.variation ? '#FFFFFF' : '#a7444d'};
  background:  ${props => props.variation ? '#a7444d' : null};
  padding:  ${props => props.variation ? '0 8px' : null};
  position: Absolute;
  bottom: -44px;
  font-size: 12px;
  width: 100%;
 

  @media (min-width: 768px) {
    bottom: -47px;
  }
  @media (min-width: 1024px) {
    bottom: ${props => props.variation ? ' -55px' : '-38px'};

    left: ${props => props.variation ? ' 0' : '10px'};
  }

  @media (min-width: 1366px) {
    left: 0;
  }
`
