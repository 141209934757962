export const theme = {
  globalPaddingDesktop: '0px 120px',
  globalPaddingMobile: '0px 10px',
  mobileGutter: 25,
  desktopGutter: 20,
  largeGutter: 40,
  mainMaxWidth: 1280,
}

export const unearth = {
  globalPaddingDesktop: '0px 120px',
  globalPaddingMobile: '0px 10px',
  mainTextColor: '#212C1F',
  headerTextColor: '#212C1F',
  textTransform: 'none',
  otherColor: '#000000',
  headerFont: `'Maison Neue','Helvetica Neue','Arial',sans-serif;`,
}

export const defaultHome = {
  globalPaddingDesktop: '0px 120px',
  globalPaddingMobile: '0px 10px',
  mainTextColor: '#212C1F',
  headerTextColor: '#ffffff',
  textTransform: 'none',
  otherColor: '#000000',
  default: true,
}

export const travis = {
  globalPaddingDesktop: '0px 120px',
  globalPaddingMobile: '0px 10px',
  mainTextColor: '#FFFFFF',
  headerTextColor: '#FFF',
  textTransform: 'none',
  backgroundColor: '#000',
  otherColor: '#000000',
  headerFont: `'valucobase','Helvetica Neue','Arial',sans-serif;`,
}

export const saori = {
  globalPaddingDesktop: '0px 120px',
  globalPaddingMobile: '0px 10px',
  mainTextColor: '#807B29',
  headerTextColor: '#807B29',
  textTransform: 'none',
  backgroundColor: '#F8ECE3',
  otherColor: '#000000',
  headerFont: `'reforma_1918blanca','Helvetica Neue','Arial',sans-serif;`,
}

export const jones = {
  globalPaddingDesktop: '0px 120px',
  globalPaddingMobile: '0px 10px',
  mainTextColor: '#EDE0D1',
  headerTextColor: '#EDE0D1',
  textTransform: 'none',
  backgroundColor: '#553E36',
  otherColor: '#000000',
  headerFont: `'marshstencil','Helvetica Neue','Arial',sans-serif;`,
}
