import React, { Component } from 'react'
import { Link } from 'gatsby'

import {
  HoverContainer,
  ThemeContainer,
  SubHeader,
  TeaserContainer,
  Theme,
  ThemeGrid,
  MoreOptions,
  TeaserGrid,
} from './style'
import { Teaser } from '../Teaser'

class NavHover extends Component {
  constructor(props) {
    super(props)

    this.themeGridRef = React.createRef()

    this.state = {
      addColumn: false,
      fadeInContent: false,
    }
  }

  componentDidMount() {
    // console.log(this.props)

    const numberOfThemes = this.themeGridRef.current.children.length

    if (numberOfThemes > 8) {
      this.setState({
        addColumn: true,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.hovered && !this.state.fadeInContent) {
      setTimeout(() => {
        this.setState({
          fadeInContent: true,
        })
      }, 10)
    }

    if (!this.props.hovered && prevProps.hovered && this.state.fadeInContent) {
      this.setState({
        fadeInContent: false,
      })
    }
  }

  render() {
    return (
      <HoverContainer menuOpen={this.props.menuOpen}>
        <ThemeContainer fadeInContent={this.state.fadeInContent}>
          <SubHeader>{this.props.heading}</SubHeader>

          <p>{this.props.description}</p>

          <ThemeGrid
            ref={this.themeGridRef}
            addColumn={this.state.addColumn}
            fadeInContent={this.state.fadeInContent}
          >
            {this.props.megaMenu.map((menuItem, index) => (
              <Theme key={index} addColumn={this.state.addColumn}>
                <svg className={`icon ${menuItem.menuIcon}`}>
                  <use
                    xlinkHref={`/images/symbol-defs.svg#${menuItem.menuIcon}`}
                  />
                </svg>

                <h4>
                  <Link
                    to={
                      menuItem.pageLink
                        ? menuItem.pageLink.uri
                        : menuItem.pageLinkCustom?.length
                        ? menuItem.pageLinkCustom
                        : '/'
                    }
                  >
                    {menuItem.label}
                  </Link>
                </h4>
              </Theme>
            ))}
          </ThemeGrid>
          <MoreOptions>
            <h5>
              <Link to={this.props.path}>GET AN OVERVIEW</Link>
            </h5>

            <h5>
              <Link to="/search">SEARCH EVERYTHING</Link>
            </h5>
          </MoreOptions>
        </ThemeContainer>

        {this.props.featuredArticles && (
          <TeaserContainer>
            <SubHeader>Don't miss these</SubHeader>

            <TeaserGrid fadeInContent={this.state.fadeInContent}>
              {this.props.hovered &&
                this.props.featuredArticles.map((teaser, index) => (
                  <Teaser
                    key={index}
                    header
                    openInNewTab
                    url={teaser.pageLink?.uri}
                    title={teaser.title}
                    desc={teaser.description}
                    img={{
                      ...teaser.image,
                      sizes: '154px',
                    }}
                  />
                ))}
            </TeaserGrid>
          </TeaserContainer>
        )}
      </HoverContainer>
    )
  }
}

export default NavHover
