import styled from 'styled-components'
import { Link } from 'gatsby'
import { device } from '../../utils/breakpoints'

export const Toggle = styled.button`
  img {
    margin: 0;
  }
`

export const AllResultsWrap = styled(Link)`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  color: #202c1f;

  span {
    display: none;

    @media (${device.tablet}) {
      display: inline;
    }
  }
`

export const InstantSearch = styled.div`
	/* display: ${props => (props.show ? 'block' : 'none')}; */
	background: #fbf9f6;
	box-shadow: 0 50px 50px 0 rgba(0, 0, 0, 0.15);
	padding: 20px;
	top: 60px;
	left: 0;
	right: 0;
	margin: auto;
	width: 100%;
	min-height: 500px;
	height: auto;
	z-index: 10000;
	position: absolute;
	overflow: hidden;
	opacity: ${props => (props.show ? '1' : '0')};
	visibility: ${props => (props.show ? 'visible' : 'hidden')};
	pointer-events: ${props => (props.show ? null : 'none')};

	transition: 330ms;

@media (${device.tablet}) {
		padding: 20px 50px;
		top: 60px;
	}

	@media (min-width: 1024px) {
		width: 94.202%;

		top: 70px;
		min-height: 561px;
	}

	@media (min-width: 1280px) {
		width: 94.202%;
    min-height: 650px;
		max-width: 1380px;

		top: ${props =>
      props.sticky
        ? '80px'
        : props.generic && !props.headerImage
        ? '101px'
        : '101px'};
	}

	@media(min-width: 1460px) {
width: 100%;

	}
`

export const SearchInput = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;

  img {
    height: 20px;
    width: 20px;
    margin: 0 12px 0 0;
    flex-grow: 0;
    flex-shrink: 0;

    @media (${device.mobile}) {
      height: 24px;
      width: 24px;
    }

    @media (${device.tablet}) {
      margin: 0 20px 0 0;
    }
  }

  .query {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #212c1f;

    @media (${device.tablet}) {
      padding-bottom: 15px;
    }
  }

  input {
    border: 0;
    background: none;
    color: #202c1f;
    font-family: 'Freight Big', serif;
    font-size: 20px;
    width: 100%;
    display: block;
    outline: none;

    @media (${device.mobile}) {
      font-size: 28px;
    }

    @media (${device.desktop}) {
      font-size: 32px;
    }

    &::placeholder {
      color: #202c1f;
      opacity: 1;
    }
  }

  .tip {
    color: #545c52;
    font-size: 16px;
    font-weight: 200;
    font-family: 'Maison Neue', sans-serif;
    margin-top: 40px;
  }

  &.hasQuery {
  }
  ${AllResultsWrap} {
    position: absolute;
    top: 0;
    right: 0;
  }
`

export const Results = styled.div`
  display: ${props => (props.totalResults ? 'flex' : 'none')};
  flex-direction: column;

  @media (${device.desktop}) {
    flex-direction: row;
  }
`

export const ResultColumn = styled.div`
  flex: 1;
  border-top: 1px solid #b9bab4;

  @media (${device.desktop}) {
    border-top: none;
    padding: 0 0 0 30px;
    margin-top: 10px;
  }

  &:first-child {
    border-top: none;

    @media (${device.desktop}) {
      border-right: 1px solid #b9bab4;
      padding: 0 30px 0 0;
    }
  }

  .title {
    font-family: 'Maison Neue', sans-serif;
    font-weight: 200;
    font-size: 14px;
    border-bottom: 1px solid #b9bab4;
    padding: 10px 0;
    margin: 0;

    @media (${device.desktop}) {
      padding: 24px 0;
      margin-top: -10px;
    }
  }
`

export const Result = styled.div`
  h1 {
    font-size: 20px;
  }

  p {
    font-size: 12px;
  }

  em {
    background: yellow;
  }
`

export const ResultCount = styled.div`
  border: 1px solid #889390;
  height: 30px;
  width: 30px;
  font-size: 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #212c1f;
  font-size: 16px;
  font-weight: 400;
  margin-right: 16px;

  @media (${device.desktop}) {
    height: 40px;
    width: 40px;
  }
`

export const OtherResult = styled(Link)`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #b9bab4;
  text-decoration: none;
  padding: 20px 0;

  &:last-child {
    border-bottom: none;
  }

  .types {
    flex-grow: 0;
    width: 16px;
    margin-right: 20px;

    @media (${device.tablet}) {
      width: 22px;
      margin-right: 26px;
    }
  }

  .content {
    flex: 1;
    color: #212c1f;
    font-size: 14px;

    @media (${device.tablet}) {
      font-size: 16px;
    }

    em {
      font-style: normal;
      background-color: #f2e4cd;
    }
  }

  h4 {
    font-size: 16px;
    color: #212c1f;

    @media (${device.tablet}) {
      font-size: 18px;
    }
  }

  svg {
    height: 16px;
    width: 16px;
    margin: 0 0 10px 0;
    display: block;

    @media (${device.tablet}) {
      height: 22px;
      width: 22px;
      margin: 0 0 15px 0;
    }

    * {
      stroke: #a55331;
    }
  }
`

export const EventResult = styled(OtherResult)`
  .types {
    flex-grow: 0;
    width: 30%;
    margin-right: 20px;

    img {
      width: 100%;
      border: 0;
      margin: 0;
    }

    @media (${device.tablet}) {
      /* width: 150px; */
      margin-right: 26px;
    }
  }
`
