import styled from 'styled-components';

export const IconsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	opacity: 1;
	width: 100% !important;
	pointer-events: none;
`;

export const IconWrap = styled.div`
	width: 100% !important;
	display: grid;
	place-items: center;
	grid-template-columns: ${(props) => (props.standard ? '32px auto' : props.search ? '32px auto' : ' 60px auto')};
	grid-template-rows: ${(props) => (props.standard ? '34px' : props.search ? '34px' : ' 64px')};

	z-index: 40;
	margin-top: -1px;
	pointer-events: none;

	@media (min-width: 480px) {
		grid-template-columns: 45px auto;
		grid-template-rows: 48px;
	}

	@media (min-width: 1024px) {
		grid-template-columns: 60px auto;
		grid-template-rows: 64px;
	}

	a {
		pointer-events: all;

		display: flex;
		color: white;
		justify-content: center;
		text-decoration: none;
		border-right: 1px solid white;
		border-left: 1px solid white;
		transition: 300ms;
		border-top: 1px solid rgba(255, 255, 255, 0);
		border-bottom: 1px solid rgba(255, 255, 255, 0);
		width: ${(props) => (props.standard ? '32px' : props.search ? '32px' : props.fullWidth ? '60px' : '60px')};

		:hover {
			border-top: 1px solid rgba(255, 255, 255, 0.5);
			border-bottom: 1px solid rgba(255, 255, 255, 0.5);
			pointer-events: auto;

			+ div {
				transition: 200ms;
				opacity: 1;
			}
		}
		@media (min-width: 480px) {
			width: 45px;
		}
		@media (min-width: 1024px) {
			width: 60px;
		}
	}

	:last-of-type {
		a {
			border-bottom: 1px solid white;
			border-top: 1px solid rgba(255, 255, 255, 0);

			:hover {
				border-top: 1px solid rgba(255, 255, 255, 0.5);
				border-bottom: 1px solid rgba(255, 255, 255, 1);
			}
		}
	}

	:first-of-type {
		/* margin-top:-2px; */

		a {
			border-top: 1px solid white;
			border-bottom: 1px solid rgba(255, 255, 255, 0);

			:hover {
				border-bottom: 1px solid rgba(255, 255, 255, 0.5);
				border-top: 1px solid rgba(255, 255, 255, 1);
			}
		}
	}

	:only-of-type {
		a {
			border-top: 1px solid white;
			border-bottom: 1px solid white;

			:hover {
				border-bottom: 1px solid rgba(255, 255, 255, 1);
				border-top: 1px solid rgba(255, 255, 255, 1);
			}
		}
	}

	svg {
		path {
			fill: none;
			stroke: #fff;
		}

		margin: ${(props) => (props.standard ? '8px 0' : props.search ? '8px 0' : '19px 0')};
		justify-self: center;
		height: ${(props) => (props.standard ? '16px' : props.search ? '16px' : '24px')};
		width: ${(props) => (props.standard ? '16px' : props.search ? '16px ' : '24px')};

		@media (min-width: 480px) {
			height: 20px;
			width: 20px;
			margin: 13px 0;
		}

		@media (min-width: 1024px) {
			height: 24px;
			width: 24px;
			margin: 19px 0;
		}
	}

	div {
		display: flex;
		padding: 0 8px;
		justify-content: center;
		align-content: center;
		align-items: center;
		justify-self: left;
		/* width: 100% !important; */
		height: 100%;
		font-size: 14px;
		/* text-align: center; */
		font-family: 'Maison Neue';
		font-weight: 700;
		background: #fbf9f6;
		color: #212c1f;
		opacity: 0;
		transition: 160ms;
		max-width: 70%;
		pointer-events: none;	
		/* max-width: 240px; */

		@media (min-width: 480px) {
			/* width: auto !important; */
			padding: 0 10px;
			/* text-align: left; */
	
			/* white-space: nowrap; */
		}

		@media (min-width: 1024px) {
			padding: 0 16px;

		}
		h2 {
			font-family: 'Maison Neue';
			font-weight: 700;
			line-height: 100%;
			margin: 0;
			display: inline-block;
			font-size: ${(props) => (props.search || props.standard ? '9px' : '12px')};
 
			@media (min-width: 480px) {
				font-size: 12px;
			line-height: 114%;

			}

			@media (min-width: 1024px) {
				font-size: 14px;
			
			}
		}
	}
`;
