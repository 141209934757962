import React, { Component } from 'react';

import Item from './item.js';
import { Container} from './style.js';


class QuickLinks extends Component {
	render() {
		return (
	
			<Container>
				<Item label="Events " icon="calendar" path="/whats-on"/>
				<Item label="Attractions" icon="star" path="/things-to-do/experience-our-top-attractions/"/>
				<Item label="Stay" icon="bed" path="/places-to-stay"/>
				<Item label="Food & Drink" icon="knife-fork" path="/food-drink"/>
				<Item label="Plan" icon="markers" path="/things-to-do/itineraries"/>


			</Container>
		);
	}
}

export default QuickLinks;
