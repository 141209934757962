import React, { Component } from 'react'
import { Link } from 'gatsby'
import { ItemWrapper } from './style.js'

class Item extends Component {
  render() {
    return (
      <ItemWrapper>
        <Link to={this.props.path}>
          <svg className={`icon icon-${this.props.icon}`}>
            <use
              xlinkHref={`/images/symbol-defs.svg#icon-${this.props.icon}`}
            />
          </svg>
          <span>{this.props.label}</span>
        </Link>
      </ItemWrapper>
    )
  }
}

export default Item
